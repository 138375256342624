import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Section, Box } from '@safelyq/bulma-ui-library';
import SidebarAI from './components/SidebarAI';
import MainSectionAI from './components/MainSectionAI';
// import "./style.css";
import { useSearchBusiness, useCHatSLBText, useFetchConversationFavourites, useChatSLBConversations, useChatSLBConverastionMsgs, useAddRemoveConversationFavBusinesses, useFetchConversationAppointments, useFetchChatSLBMessagesConversation } from './hooks';
import { useLocation } from 'hooks/useLocation';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import ConversationModules from './components/ConversationModules';
import moment from 'moment';
import { toast } from 'react-toastify';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';

const SearchBusinessAI = () => {

    // Get the coordinates (longitude and latitude) from the browser
    const { coords } = useLocation();
    const history = useHistory();
    const [favBusinesses, setFavBusinesses] = useState([]);
    const [conversationAppointments, setConversationAppointments] = useState([]);

    const [conversationMessagesList, setConversationMessagesList] = useState([]);
    const [selectedBusinessForMessage, setSelectedBusinessForMessage] = useState(0);
    const [selectedBusinessForAppointment, setSelectedBusinessForAppointment] = useState(0);
    const [editAppointmentId, setEditAppointmentId] = useState({
        id: 0,
        modal: false,
        appointment: null
    });

    const [previousConversation, setPreviousConversations] = useState([]);

    const previousConversationTotal = useRef();
    const yesterday = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');

    // set the length of the conversation
    useEffect(() => {
        if (previousConversation?.length !== 0) {
            previousConversationTotal.current = previousConversation?.length;
        }
    }, [previousConversation])

    const [showMessagingScreens, setShowMessagingScreens] = useState({
        list: true,
        chat: false,
        modal: false,
    })

    const [isPagination, setIsPagination] = useState(false); // New flag to track pagination conversation
    const [showAppointmentsScreens, setShowAppointmentsScreens] = useState({
        list: true,
        appointment: false
    })

    const [newMessagePopup, setNewMessagePopup] = useState({
        status: false,
        businessId: null,
    })

    // All conversation / messgaes from global state
    const [allList, setallList] = useState([]);

    // Used in the business conversation messaging
    const [chatRights, setChatRights] = useState({
        messagingAllowed: false,
        consumerResponseAllowed: false,
        consumerAttachmentsAllowed: false,
        consumerInitiationAllowed: false
    })


    // If no messaging is active than show list instead of chat
    useEffect(() => {
        if (selectedConversationId) {
            const conversationExists = allList?.find(
                conversation => conversation?.business?.id === selectedBusinessForMessage && conversation?.active
            );

            // If modal is opened then return bacuse some action is about to be performed else proceed to screen
            if (showMessagingScreens?.modal) {
                return;
            }

            if (conversationExists) {
                setShowMessagingScreens({ modal: false, chat: true, list: false });
            } else {
                setShowMessagingScreens({ modal: false, chat: false, list: true });
                setChatRights({
                    messagingAllowed: false,
                    consumerResponseAllowed: false,
                    consumerAttachmentsAllowed: false,
                    consumerInitiationAllowed: false
                })
                userMessageConversationRefetch(parseInt(selectedConversationId)); // call the list user conversation messaging 
            }
        }
    }, [allList])

    const allMessages = useSelector((state) => state.auth?.allMessages);

    // Fetch all messagings from global state and set to state
    useEffect(() => {
        setallList(allMessages)
    }, [allMessages])

    const totalMessagesCount = useRef(null);
    const totalConversationsCount = useRef(null);
    const [selectedConversationId, setSelectedConversationId] = useState(0);

    const [showStatus, setShowStatus] = useState({
        favouriteBusiness: false,
        messaging: false,
        appointments: false
    })

    // Take the view to the Messaging div
    const messagingDivRef = useRef(null);
    const messagingDivRefMobile = useRef(null);

    const allowChatSLB = useSelector(state => state?.consumer?.allowChatSLB);

    // Chat SLB is not allowed
    if (!allowChatSLB) {
        history.push('/')
    }

    // New message popup
    const handleClosePopup = () => {
        setNewMessagePopup({
            ...newMessagePopup,
            status: false,
        })

        userMessageConversationRefetch(parseInt(selectedConversationId)); // call the list user conversation messaging 
        setShowMessagingScreens({ ...showMessagingScreens, modal: false })
    }

    const updateCommentsInTheState = (comment) => {
        // Update the SingleAppointment
        let dummyAppointment = {
            ...editAppointmentId?.appointment,
            comments: [...editAppointmentId?.appointment?.comments, comment] // Creating a new array with the updated comments
        };

        setEditAppointmentId(prevState => ({
            ...prevState,
            appointment: dummyAppointment
        }));

        // Update the Conversation Appointments
        const updatedAppointments = conversationAppointments.map(appointment => {
            if (appointment?.id === editAppointmentId?.appointment?.id) {
                // Create a new array with the added comment
                return {
                    ...appointment,
                    comments: [comment, ...appointment?.comments]
                };
            }
            return appointment; // If no match, return the original object
        });

        // Update state with the new array
        setConversationAppointments(updatedAppointments);

    }

    const [initialMessage, setInitialMessages] = useState([
        { id: 1, text: 'Hello! How can I assist you?', sender: 'ai', businesses: [] },
    ]);

    const [messages, setMessages] = useState([]);
    const [paginationForMsgs, setPaginatingForMsgs] = useState(false); // Converasation messages

    // For Conversations
    const [pagination, setPagination] = useState({
        skip: 0,
        take: 25
    })


    // For Conversation Messages
    const [paginationMsgs, setPaginationMsgs] = useState({
        skip: 0,
        take: 6,
        // take: 4,
    })

    const scrollableDivRef = useRef(null);
    const scrollableDivMessagesRef = useRef(null);

    const [userStatement, setUserStatement] = useState(null);
    const [input, setInput] = useState('');
    const inputRef = useRef('');

    // Get ChatSLB Text To Display
    const {
        getChatSLBText,
        isLoadingText,
        isErrorText,
        textData,
    } = useCHatSLBText();

    const chatSLBTextData = textData?.getChatSLBPageInfo?.chatSLBInfo;

    // Mutation for add remove fav of single conversation
    const {
        handleFavouriteBusinesses,
        conversationFavouriteLoading,
    } = useAddRemoveConversationFavBusinesses();

    // Get Single Converdsation Favourites
    const {
        getConversationFavBusinesses,
        isLoadingConFavs,
        isErrorConFavs,
        favConData,
    } = useFetchConversationFavourites();

    // Get Single Converdsation Appointments
    const {
        getConversationAppointments,
        refetchConversationAppointments,
        isLoadingConAppointments,
        isErrorConAppointments,
        conAppointmentsData,
    } = useFetchConversationAppointments();

    // Set Conversation Appointments to state
    useEffect(() => {
        conAppointmentsData?.getUserConversationAppointments && setConversationAppointments(conAppointmentsData?.getUserConversationAppointments)
    }, [conAppointmentsData])

    // Get Single Converdsation Messages
    const {
        getUserChatSLBMessageConversations,
        userMessageConversationRefetch,
        isLoadingConMessages,
        isErrorConMessages,
        conMessagesData,
    } = useFetchChatSLBMessagesConversation();

    // Set Conversation Messages
    useEffect(() => {
        conMessagesData?.getUserChatSLBMessageConversations && setConversationMessagesList(conMessagesData?.getUserChatSLBMessageConversations)
    }, [conMessagesData])

    // Get all the chat conversations
    const {
        getChatSLBConversation,
        refetchAllConversations,
        isLoadingConversation,
        isErrorConversation,
        conversationData,
        onUpdateConversationName,
        onDeleteConversation,
    } = useChatSLBConversations();

    const [showDeleteModal, setShowDeleteModal] = useState({
        status: false,
        id: 0
    });


    const handleDeleteConversation = async (id) => {
        // Show confirmation dialog
        const isConfirmed = window.confirm("Are you sure you want to delete this conversation?");

        // If the user confirms, proceed with the deletion
        if (isConfirmed) {
            const result = await onDeleteConversation({
                variables: {
                    conversationId: parseInt(id),
                    delinkRecord: false,
                    deleteRecord: false,
                }
            });


            const isSaved = result?.data?.deleteChatSLBConversation?.isSaved;
            const isDisplayModel = result?.data?.deleteChatSLBConversation?.isDisplayModel;

            // If successful, filter the conversation in the state
            if (isSaved && !isDisplayModel) {
                toast.success("ChatSLB conversation deleted successfully")
                const dummy = previousConversation?.filter(conversation => conversation?.id !== id);
                setPreviousConversations(dummy);

                // if the current conversation is being selected and deleted, null its messages and remove selected conversation id
                if (selectedConversationId === id) {
                    setMessages([])
                    setShowInput(false)
                    setSelectedConversationId(0);
                    setFavBusinesses([]) // empty fav businesses
                    setConversationMessagesList([]); // empty the old messaging list
                    setConversationAppointments([]); // empty the old appointments list
                }
            }

            // Check the conditions via delete modal
            else if (!isSaved && isDisplayModel) {
                setShowDeleteModal({
                    status: true,
                    id: id
                });
            }
        }
    };

    const handleDeleteConversationConfirm = async (status) => {

        if (status == "close") {
            setShowDeleteModal({
                status: false,
                id: 0,
            })
            return;
        }

        let variables = {
            conversationId: parseInt(showDeleteModal?.id),
            delinkRecord: false,
            deleteRecord: false,
        };

        if (status === "delink") {
            variables.delinkRecord = true;
        }

        if (status === "delete") {
            variables.deleteRecord = true;
        }

        const result = await onDeleteConversation({
            variables
        });


        const isSaved = result?.data?.deleteChatSLBConversation?.isSaved;
        const isDisplayModel = result?.data?.deleteChatSLBConversation?.isDisplayModel;

        // If successful, filter the conversation in the state
        if (isSaved) {
            toast.success("ChatSLB conversation deleted successfully")
            const dummy = previousConversation?.filter(conversation => conversation?.id !== showDeleteModal?.id);
            setPreviousConversations(dummy);
            setShowDeleteModal({
                status: false,
                id: 0
            })

            // if the current conversation is being selected and deleted, null its messages and remove selected conversation id
            if (selectedConversationId === showDeleteModal?.id) {
                setMessages([])
                setShowInput(false)
                setSelectedConversationId(0);
                setFavBusinesses([]) // empty fav businesses
                setConversationMessagesList([]); // empty the old messaging list
                setConversationAppointments([]); // empty the old appointments list
            }
        }

    }

    useEffect(() => {

        // Set previous conversation from API to the state
        if (conversationData && conversationData?.getChatSLBConversations?.chatSLBConversations?.length !== 0) {
            totalConversationsCount.current = conversationData?.getChatSLBConversations?.totalCount;

            const fetchedConversations = conversationData.getChatSLBConversations.chatSLBConversations;

            if (isPagination) {
                // If pagination, append the new conversations to the previous list
                setPreviousConversations((prevConversations) => [
                    ...prevConversations,
                    ...fetchedConversations
                ]);

                setIsPagination(false); // Reset the flag after appending paginated conversations
            } else {

                // Running first time
                if (previousConversation?.length === 0) {
                    setPreviousConversations(fetchedConversations)
                }
                else {
                    // Not pagination, just updating the list after the new conversation ahs started
                    setPreviousConversations(fetchedConversations)
                }
            }
        }
    }, [conversationData])

    // Get all the chat conversation messages
    const {
        getChatSLBConversationMsgs,
        isLoadingConversationMsgs,
        isErrorConversationMsgs,
        conversationMsgsData,
    } = useChatSLBConverastionMsgs();


    useEffect(() => {
        // Set previous conversation messages from API to the state
        if (conversationMsgsData?.getConversationMessages?.totalCount) {
            const total = conversationMsgsData?.getConversationMessages?.totalCount;

            totalMessagesCount.current = total;
        }
        if (conversationMsgsData?.getConversationMessages?.conversationMessages) {

            const conversationMessages = conversationMsgsData?.getConversationMessages?.conversationMessages;
            let responseArray = [];
            const response = conversationMessages?.map(message => {
                let responseUser = {
                    customId: messages?.length + 1,
                    id: message?.id,
                    text: message?.userStatement || "",
                    sender: 'user',
                };

                let responseAi = {
                    customId: messages?.length + 2,
                    id: message?.id,
                    text: message?.geminiResponse,
                    sender: 'ai',
                    businesses: message?.businesses
                };

                responseArray.push(responseAi, responseUser,);
            });

            // so it becomes bottom up order for messages
            paginationMsgs?.skip > 2
                ?
                setMessages([...messages, ...responseArray,]) // Add to the start of the conversation
                :
                setMessages([...responseArray]) // first time conversation is being fetched

            setShowInput(true) // show the screen
        }
    }, [conversationMsgsData])

    useEffect(() => {
        favConData?.getUserConversationFavorites && setFavBusinesses(favConData?.getUserConversationFavorites)
    }, [favConData])

    // Get Messages from a specific chat
    const onSelectChat = (chat) => {
        setMessages([]); // as a new converrsation is selected - empty old conversation messages
        setFavBusinesses([]); // as a new converrsation is selected - new favs will be loaded
        setPaginationMsgs({
            skip: 0,
            take: 6,
        }) // set the pagination to 0 once new conversation is called
        setConversationMessagesList([]); // empty the old messaging list
        setConversationAppointments([]); // empty the old appointments list
        setShowMessagingScreens({ modal: false, chat: false, list: true }); // show the list instead of the chat when conversation changes
        totalMessagesCount.current = 0;
        getChatSLBConversationMsgs({ variables: { ...paginationMsgs, conversationId: chat?.id } }) // Get mesages of single conversation
        setSelectedConversationId(chat?.id) // set and send conversation id along with the conversation
        getConversationFavBusinesses({ variables: { conversationId: parseInt(chat?.id) } }) // Get Favorite Businesses of Single Conversation
        getUserChatSLBMessageConversations({ variables: { conversationId: parseInt(chat?.id) } }) // Get Conversation Messages
        getConversationAppointments({ variables: { conversationId: parseInt(chat?.id), startDate: yesterday } }) // Get Conversation Appointments
    }

    // Edit appointment details
    const handleAppointmentClicked = (appointment) => {
        setEditAppointmentId({
            modal: true,
            id: appointment?.id,
            appointment: appointment
        })
    }
    const handleCloseAppointmentModal = () => {

        setEditAppointmentId({
            modal: false,
            id: 0,
            appointment: null
        })
        refetchConversationAppointments({ variables: { conversationId: parseInt(selectedConversationId), startDate: yesterday } });
    }

    useEffect(() => {
        // if the conversation id is selected than trigger this
        if (selectedConversationId) {
            getChatSLBConversationMsgs({ variables: { ...paginationMsgs, conversationId: selectedConversationId } }) // Get mesages of single conversation
        }
    }, [paginationMsgs?.skip])

    // Send Messages and get response
    const {
        getBusinessesByGemini,
        isLoadingResponse,
        isErrorResponse,
        data,
        isRefetchBusinesses,
    } = useSearchBusiness();


    useEffect(() => {
        getChatSLBText(); // Get ChatSLB texts
        getChatSLBConversation({ variables: { ...pagination } }); // get previous conversation for first time only
    }, [])

    useEffect(() => {
        // if (selectedConversationId !== 0) {
        if (pagination?.skip !== 0) {
            getChatSLBConversation({ variables: { ...pagination } }); // get previous conversation
        }
    }, [pagination?.skip])

    // For Conversations Scroll event handler
    const handleScroll = () => {
        if (scrollableDivRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollableDivRef.current;

            // Check if the user has scrolled to the bottom
            if (scrollTop + clientHeight >= scrollHeight) {
                // Check if we have loaded all conversations

                if (previousConversationTotal?.current >= totalConversationsCount.current) {
                    // No more conversations to load
                    return;
                }

                // Update the pagination state to load more notifications
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    skip: prevPagination.skip + prevPagination?.take,
                    // skip: prevPagination.skip + 18,
                    take: 10,
                }));
                setIsPagination(true); // Set the flag indicating pagination is happening
            }
        }
    };

    useEffect(() => {
        const scrollDiv = scrollableDivRef.current;
        if (scrollDiv) {
            scrollDiv.addEventListener('scroll', handleScroll);
            // Cleanup the event listener on component unmount
            return () => {
                scrollDiv.removeEventListener('scroll', handleScroll);
            };
        } else {
        }
    }, [scrollableDivRef.current]);

    // For Conversation Messages inside the conversation Scroll event handler
    const handleScrollMsgs = () => {
        if (scrollableDivMessagesRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollableDivMessagesRef.current;

            const tolerance = 1; // Small tolerance for differences in scroll measurements

            // Detect if the user has scrolled to the top (scrollTop = 0)
            const isAtTop = scrollTop === 0;

            // Capture the current scroll height before fetching new messages
            scrollableDivMessagesRef.current.previousScrollHeight = scrollHeight;

            // Use the current state value of paginationMsgs inside setPaginationMsgs to ensure it is up-to-date
            !isLoadingConversationMsgs && setPaginationMsgs((prevPagination) => { // !isLoadingConversationMsgs so that it doesnot call apis even when one is being called

                // Calculate the total number of messages already fetched
                const totalFetched = prevPagination?.skip + prevPagination?.take;

                // Calculate the remaining messages to fetch
                const remainingMessages = totalMessagesCount.current - totalFetched;

                // Ensure we don't request more messages than available
                const take = Math.min(4, remainingMessages); // Take either 4 or the remaining messages if less than 4

                // If we already fetched all messages, prevent further API calls
                if (take === 0) {
                    return prevPagination; // No changes if no more messages to fetch
                }

                // Check if there are still messages left to fetch
                const shouldFetchMore = remainingMessages > 0;

                if (isAtTop && shouldFetchMore) {
                    setPaginatingForMsgs(true); // Set paginating flag when fetching older messages

                    return {
                        ...prevPagination,
                        skip: prevPagination?.skip + 4, // Increment skip by the number of messages fetched
                        take: take, // Adjust take dynamically to fetch the remaining messages
                    };
                }

                return prevPagination; // No changes if no more messages to fetch
            });
        }
    };

    // Debounce the scroll handler to limit how often it fires
    const debouncedHandleScrollMsgs = debounce(handleScrollMsgs, 300); // 300ms debounce delay

    useEffect(() => {

        const scrollDiv = scrollableDivMessagesRef.current;
        if (scrollDiv) {

            scrollDiv.addEventListener('scroll', debouncedHandleScrollMsgs);

            // Cleanup the event listener on component unmount
            return () => {
                scrollDiv.removeEventListener('scroll', debouncedHandleScrollMsgs);
            };
        } else {
        }
    }, [scrollableDivMessagesRef.current, debouncedHandleScrollMsgs]);

    useEffect(() => {
        if (input) {
            inputRef.current = input
        }
    }, [input])

    const [showInput, setShowInput] = useState(false);
    useEffect(() => {
        if (data?.searchBusinessesByGemini?.isSuccess) {
            // If detailed response exist than set in the messages
            let response;
            response = {
                id: messages.length + 1,
                text: data?.searchBusinessesByGemini?.detailedDescription || "",
                sender: 'ai',
                businesses: data?.searchBusinessesByGemini?.businesses || []
            };

            setMessages([response, ...messages])

            // if the selctedConversationId is 0 means it is the first msg of the conversation - refetch updated conversations
            if (selectedConversationId === 0) {

                setPreviousConversations([]); // empty because new conversations are being refetched

                // As the previous conversations are null and pagination is also set to the initial state
                setPagination({
                    skip: 0,
                    take: 25
                })
                // getChatSLBConversation({ variables: { ...pagination } }); // get previous conversation
                getChatSLBConversation({
                    variables: {
                        skip: 0,
                        take: 25
                    }
                }); // get previous conversation
            };
            setSelectedConversationId(data?.searchBusinessesByGemini?.conversationId) // set the converation id
        }
    }, [data])

    const handleSend = () => {
        const inputValue = inputRef.current.trim();
        if (inputValue) {
            setShowInput(true); // show the messages area
            setMessages(prevMessages => [
                { id: prevMessages.length + 1, text: inputValue, sender: "user", type: "text" }, // add the new message to the start of the converstrion
                ...prevMessages,
            ]);
            setUserStatement(inputValue);
            setInput(''); // Clear the input after sending
            inputRef.current = '';
        }
    };

    useEffect(() => {
        // if userStatement is null than return 
        if (!userStatement) {
            return;
        }

        // Call By Graphql
        userStatement && getBusinessesByGemini({
            variables: {
                searchBusinessByGeminiInput: {
                    userStatement: userStatement,
                    latitude: coords?.latitude,
                    longitude: coords?.longitude,
                    // isNewChat: messages?.length <= 1 ? true : false
                    conversationId: selectedConversationId,
                }
            }
        })
    }, [userStatement])

    // Props
    const sidebarProps = {
        onUpdateConversationName,
        onDeleteConversation,
        previousConversation,
        setPreviousConversations,
        setMessages,
        setShowInput,
        onSelectChat,
        selectedConversationId,
        setSelectedConversationId,
        setFavBusinesses,
        scrollableDivRef,
        setPagination,
        setConversationMessagesList,
        setConversationAppointments,
        handleDeleteConversation
    };

    const mainSectionProps = {
        setUserStatement,
        setShowInput,
        setMessages,
        showInput,
        input,
        messages,
        handleSend,
        setInput,
        loading: isLoadingResponse,
        isLoadingConversationMsgs,
        chatSLBTextData,
        isLoadingText,
        favBusinesses,
        setFavBusinesses,
        handleFavouriteBusinesses,
        selectedBusinessForMessage,
        setSelectedBusinessForMessage,
        showStatus,
        setShowStatus,
        messagingDivRef,
        messagingDivRefMobile,
        selectedBusinessForAppointment,
        setSelectedBusinessForAppointment,
        previousConversation,
        selectedConversationId,
        onUpdateConversationName,
        onDeleteConversation,
        setPreviousConversations,
        onSelectChat,
        setSelectedConversationId,
        scrollableDivMessagesRef,
        newMessagePopup,
        setNewMessagePopup,
        showMessagingScreens,
        setShowMessagingScreens,
        allList,
        showAppointmentsScreens,
        setShowAppointmentsScreens,
        paginationForMsgs,
        setPaginatingForMsgs,
        chatRights,
        setChatRights,
        setConversationMessagesList,
        setConversationAppointments,
        handleDeleteConversation
    };

    const conversationModulesProps = {
        selectedBusinessForMessage,
        setSelectedBusinessForMessage,
        selectedBusinessForAppointment,
        setSelectedBusinessForAppointment,
        isLoadingConFavs,
        messages,
        loading: isLoadingResponse,
        favBusinesses,
        setFavBusinesses,
        handleFavouriteBusinesses,
        showStatus,
        setShowStatus,
        messagingDivRef,
        messagingDivRefMobile,
        selectedConversationId,
        newMessagePopup,
        setNewMessagePopup,
        handleClosePopup,
        allList,
        showMessagingScreens,
        setShowMessagingScreens,
        showAppointmentsScreens,
        setShowAppointmentsScreens,
        conversationAppointments,
        conversationAppointments,
        conversationMessagesList,
        refetchConversationAppointments,
        getConversationAppointments,
        chatRights,
        setChatRights,
        handleAppointmentClicked,
        editAppointmentId,
        handleCloseAppointmentModal,
        updateCommentsInTheState,
        userMessageConversationRefetch,
    };

    return (
        <Section className='chatslb-section px-0 has-text-centered-mobile p-0'>
            <Box className='py-0'
                style={{
                    padding: "0.8rem",
                    height: "89vh"
                }}>
                <div className='columns responsive-class'
                    style={{
                        // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 6px 20px rgba(0, 0, 0, 0.1)",
                        borderRadius: "12px",
                        // height: "89vh"
                    }}>
                    <div className='column is-9 p-0 m-0' style={{ height: "100%" }}>
                        <div className="columns main-section-ai-custom m-0 p-0" >
                            <div className='column is-2 is-hidden-mobile'
                                style={{
                                    backgroundColor: "#f5f5f5",
                                    borderTopLeftRadius: "12px",
                                    borderBottomLeftRadius: "12px"
                                }}>
                                <div className=''>
                                    <SidebarAI sidebarProps={sidebarProps} />
                                </div>
                            </div>
                            <div className='column is-10 pt-0'>
                                <MainSectionAI mainSectionProps={mainSectionProps} />
                            </div>
                        </div>
                    </div>
                    <div className='column is-3 p-0' style={{ height: "100%", backgroundColor: "#f5f5f5", }} >
                        <ConversationModules conversationModulesProps={conversationModulesProps} />
                    </div>
                </div>

                {showDeleteModal?.status
                    &&
                    <DeleteConfirmationModal
                        showDeleteModal={showDeleteModal}
                        handleDeleteConversationConfirm={handleDeleteConversationConfirm}
                    />}
            </Box>
        </Section >
    )
}

export default SearchBusinessAI
