import React, { useEffect, useRef } from 'react'
import {
    Container,
} from '@safelyq/bulma-ui-library';
import { FcDonate } from "react-icons/fc";
import StripeCheckout from 'react-stripe-checkout';
import { FcPaid } from "react-icons/fc";
import { ClipLoader } from 'react-spinners';
import ExistingEmailFlow from './ExistingEmailFlow';

const PaymentUI = ({ paymentUIProps }) => {
    const {
        name,
        publishableKey,
        boxRef,
        handleToken,
        stripeVariables,
        isPaymentCompleted,
        loadingBusinessPayment,
        dataId,
        dataIdUsed,
        emailStatuses,
        handleSubmitPaymentFunction,
        autoPaymentLoading,
        paymentReciept
    } = paymentUIProps;

    const stripeButtonRef = useRef();
    const isLoggingInForPayment = localStorage.getItem("userLoggingInForPayment") === "true";

    useEffect(() => {
        // Trigger the Stripe modal when the publishableKey is available and  Automatically trigger the button's click event to open the Stripe modal
        if (publishableKey && stripeButtonRef.current) {
            dataIdUsed === false && stripeButtonRef.current.querySelector("button").click();
        }
    }, [publishableKey, dataIdUsed]);

    const existingEmailFlowProps = {
        handleSubmitPaymentFunction,
        autoPaymentLoading,
        loadingBusinessPayment
    }

    return (
        <Container className='py-5'>
            <div className='payment-main-component' ref={boxRef}>
                <div className='is-flex is-justify-content-center mb-4'>
                    <img
                        src={process.env.PUBLIC_URL + '/images/logo.png'}
                        alt='logo'
                        style={{ width: "180px", maxWidth: "200px" }}
                    />
                </div>

                {
                    // if data id does not exist or used and the payment is completed than show your payment has passed
                    !dataId
                        ?
                        ""
                        :
                        // if either data id is used or the payment is completed
                        (dataIdUsed || isPaymentCompleted)
                            ?
                            <div>
                                <div className='is-flex is-justify-content-center my-2'>
                                    <div className='is-flex'>
                                        <p className='is-size-5 has-text-centered has-text-weight-bold'>
                                            <span className='mx-3'>Your payment has been successfully processed! Thank you for your transaction. </span>
                                            <FcPaid fontSize={28} className='ml-2' />
                                        </p>
                                    </div>
                                </div>
                                {paymentReciept && <p
                                    onClick={() => window.open(paymentReciept, '_blank')}
                                    className='has-text-link has-text-weight-bold is-underlined is-clickable is-size-6 has-text-centered'>View your receipt</p>}
                            </div>
                            :

                            // if the email id already exist than show the flow of the other email providing or login
                            (emailStatuses?.emailExists || isLoggingInForPayment)
                                ?
                                <ExistingEmailFlow existingEmailFlowProps={existingEmailFlowProps} />
                                :
                                // data id is not used neither payment if completed
                                <>
                                    <div className='is-flex is-justify-content-center my-2'>
                                        <p className='is-size-5 has-text-weight-bold'>Ready to Proceed for the Payment </p>
                                        <FcDonate fontSize={24} className='ml-2' />
                                    </div>
                                    <hr />
                                    <div className='mt-4 is-flex is-justify-content-center'>
                                        <div className='is-flex' ref={stripeButtonRef}>
                                            {
                                                publishableKey
                                                &&
                                                <StripeCheckout
                                                    name={name}
                                                    token={handleToken}
                                                    stripeKey={publishableKey && publishableKey}
                                                    amount={stripeVariables?.amount}
                                                />
                                            }
                                            {
                                                loadingBusinessPayment
                                                &&
                                                <div style={{ marginLeft: "10px" }}>
                                                    <ClipLoader className="ml-3" size='28' />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                }

                <div className='is-flex is-justify-content-end mt-4 mb-1'>
                    <p className='has-text-grey is-size-7 has-text-weight-bold'>
                        Powered by
                        <span className='has-text-grey-dark'> ChatSLB </span> +
                        <span style={{ color: "#51c9eb" }}> Safely</span>
                        <span style={{ color: "#4580c2" }}>Q </span>
                    </p>
                </div>
            </div>
        </Container>
    )
}

export default PaymentUI