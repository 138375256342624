import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Text } from '@safelyq/bulma-ui-library';
import styled from 'styled-components';
import { toggleActiveChat } from 'Redux/AuthSlice/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GET_USER_UNREAD_MESSAGES } from 'components/Common/NavMenu/requests';
import { getAllMessagesWithLatestMessage } from 'Redux/AuthSlice/AuthSlice';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import firestore from '@firebase';
import { SlOptionsVertical } from "react-icons/sl";
import ConversationAppointments from '../ChatSLBModules/ConversationAppointments';
import ConversationMessagingDesktop from '../ChatSLBModules/ConversationMessagingDesktop';
import ConversationFavouritesDesktop from '../ChatSLBModules/ConversationFavouritesDesktop';
import ConversationMessagingMobile from '../ChatSLBModules/ConversationMessagingMobile';
import ConversationFavouritesMobile from '../ChatSLBModules/ConversationFavouritesMobile';
import moment from 'moment';

const ConversationModules = ({ conversationModulesProps }) => {
    const {
        selectedBusinessForMessage,
        setSelectedBusinessForMessage,
        selectedBusinessForAppointment,
        setSelectedBusinessForAppointment,
        isLoadingConFavs,
        favBusinesses,
        setFavBusinesses,
        handleFavouriteBusinesses,
        showStatus,
        setShowStatus,
        messagingDivRef,
        messagingDivRefMobile,
        selectedConversationId,
        newMessagePopup,
        setNewMessagePopup,
        handleClosePopup,
        allList,
        showMessagingScreens,
        setShowMessagingScreens,
        showAppointmentsScreens,
        setShowAppointmentsScreens,
        conversationAppointments,
        conversationMessagesList,
        refetchConversationAppointments,
        chatRights,
        setChatRights,
        handleAppointmentClicked,
        editAppointmentId,
        handleCloseAppointmentModal,
        updateCommentsInTheState,
        getConversationAppointments
    } = conversationModulesProps;

    const yesterday = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [allowActiveChat, setAllowActiveChat] = useState(false);
    const dropdownRef = useRef(null);
    const [latestMessage, setlatestMessage] = useState({ businessId: "", customerId: "", messageId: "" })
    const allMessages = useSelector(state => state.auth.allMessages);
    const userinfo = useSelector(state => state.auth.userinfo);
    const { pathname, state } = useLocation();
    const dispatch = useDispatch();

    // when the list changes, activate the conversation and nullify the newMessagePopUp
    useEffect(() => {
        const businessFoundInList = allList?.find(conversation => conversation?.business?.id === newMessagePopup?.businessId)
        if (newMessagePopup?.businessId && allowActiveChat && businessFoundInList) {
            handleActiveChat(newMessagePopup?.businessId);
            setAllowActiveChat(false) // chat is activated, false the state
            setNewMessagePopup({ businessId: null, status: false })
        }
    }, [allList])

    // get unread messages api
    const { refetch: refetchChat } = useQuery(GET_USER_UNREAD_MESSAGES, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {

            dispatch(getAllMessagesWithLatestMessage({
                unreadCount: data.getUser.user.conversationsSummary.unreadCount,
                allMessages: data.getUser.user.conversations,
                totalNotifications: data.isProfileComplete.unviewedCount,
                latestMessage: latestMessage
            }))

            // As list is updated, allow the activate chat module to call 
            setAllowActiveChat(true);
        },
    })

    // called here and removed from navbar because of re renders and data loss
    // This snippet is not used as query is called from MessageBox
    useEffect(() => {
        const unsubscribe = firestore.collection('chat').where('customerId', '==', `${userinfo.id}`).onSnapshot(async querySnapshot => {

            if (String(pathname).includes('/messages') || String(pathname).includes('/search-business-ai')) {
                let allChats = []
                querySnapshot.forEach((doc) => {
                    allChats.push(doc.data())
                });

                allChats.sort((a, b) => parseInt(a.messageId) - parseInt(b.messageId));
                let latestMessage = allChats.pop()
                setlatestMessage(latestMessage)
                refetchChat() // This brings the list of all the conversation
            }
        });
        return () => unsubscribe();
    }, [userinfo.id, refetchChat, pathname]);

    const [modulesChatSLB, setModulesChatSLB] = useState([{
        name: "Conversation Favourites",
        variableName: "favouriteBusiness"
    },
    {
        name: "Messaging",
        variableName: "messaging"
    },
    {
        name: "Appointments and Reservations",
        variableName: "appointments"
    }])

    const handleCloseModulesTab = (name) => {
        setShowStatus((prevStatus) => ({
            ...Object.keys(prevStatus).reduce((acc, key) => {
                acc[key] = false; // Set all properties to false
                return acc;
            }, {}),
            [name]: true // Set 'name' property to true
        }));
    }

    const handleMessaging = (id) => {

        // Send the view to the messaging div
        if (messagingDivRef.current) {
            messagingDivRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        // Scroll for mobile
        if (messagingDivRefMobile.current) {
            messagingDivRefMobile.current.scrollIntoView({ behavior: 'smooth' });
        }

        // Open the new message section if the current business messaging dont exist in global list

        // If the message is not found in the list than show modal for display
        if (!allList?.find(conversation => conversation?.business?.id === id)) {
            setNewMessagePopup({
                status: true,
                businessId: id,
            })
            setShowMessagingScreens({ ...showMessagingScreens, modal: true, list: true });
            // list is true because else empty chat screen will be shown that cant be closed
        }

        setSelectedBusinessForMessage(id);
        setShowStatus({ ...showStatus, messaging: true, favouriteBusiness: false, appointments: false }) // close all other sections
        handleActiveChat(id);
    }

    // Activate the business for messaging
    const handleActiveChat = (chatid) => {
        dispatch(toggleActiveChat({ businessId: chatid }))
    }

    const handleAppointments = (id) => {
        setShowStatus({ ...showStatus, favouriteBusiness: false, messaging: false, appointments: true }) // close all other sections
        setShowAppointmentsScreens({ appointments: true, list: false })
        setSelectedBusinessForAppointment(id)
    }

    // Custom Drop Down that closes when clicked anywhere other on the screen
    const toggleDropdown = () => {

        setIsDropdownOpen(prevState => !prevState);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCloseFunction = () => {
        handleClosePopup(); // close modal for the new popup message
    }

    const handleCloseAppointmentSection = () => {
        setShowAppointmentsScreens({ appointments: false, list: true }) // close the new appointment and show screen
        refetchConversationAppointments({ variables: { conversationId: parseInt(selectedConversationId), startDate: yesterday } });
    }

    // Messaging screen rules
    const handleBusinessSelected = (businessId, subscriptionPlan, messaging) => {

        handleMessaging(businessId);
        setChatRights({
            messagingAllowed: messaging?.messagingAllowed,
            consumerInitiationAllowed: messaging?.consumerInitiationAllowed,
            consumerResponseAllowed: messaging?.consumerResponseAllowed,
            consumerAttachmentsAllowed: messaging?.consumerAttachmentsAllowed,
        })
    }

    const handleMessagingAllowed = (businessId, subscriptionPlan, messaging) => {

        let conversationStarted = allMessages.find((chat) => chat?.business?.id === businessId);

        // check if subscribed
        if (subscriptionPlan?.features?.genericNumberMessagingAllowed) {
            // check if new conversation
            if (conversationStarted) {
                messaging?.consumerResponseAllowed // check if response allowed
                    ?
                    handleBusinessSelected(businessId, subscriptionPlan, messaging)
                    :
                    toast.warning("This business does not accept messages at this time");
            } else {
                messaging?.consumerInitiationAllowed ? // check if initiation allowed
                    handleBusinessSelected(businessId, subscriptionPlan, messaging)
                    :
                    toast.warning("This business does not accept messages at this time");
            }
        } else {
            toast.warning("This business does not accept messages at this time");
        }
    };

    const conversationAppointmentsProps = {
        showStatus,
        setShowStatus,
        selectedConversationId,
        showAppointmentsScreens,
        handleCloseAppointmentSection,
        selectedBusinessForAppointment,
        conversationAppointments,
        handleAppointmentClicked,
        editAppointmentId,
        handleCloseAppointmentModal,
        updateCommentsInTheState,
        getConversationAppointments
    }

    const conversationMessagingDesktopProps = {
        showStatus,
        setShowStatus,
        selectedConversationId,
        showMessagingScreens,
        selectedBusinessForMessage,
        conversationMessagesList,
        messagingDivRef,
        handleMessagingAllowed,
        chatRights,
        setChatRights,
        newMessagePopup,
        handleCloseFunction,
    }

    const conversationFavouritesDesktopProps = {
        showStatus,
        setShowStatus,
        selectedConversationId,
        showMessagingScreens,
        isLoadingConFavs,
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        handleMessagingAllowed,
        handleAppointments,
        handleMessaging
    }

    const conversationFavouritesMobileProps = {
        showStatus,
        setShowStatus,
        selectedConversationId,
        showMessagingScreens,
        isLoadingConFavs,
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        handleMessagingAllowed,
        handleAppointments,
        handleMessaging
    }

    const conversationMessagingMobileProps = {
        showStatus,
        setShowStatus,
        selectedConversationId,
        showMessagingScreens,
        selectedBusinessForMessage,
        conversationMessagesList,
        messagingDivRefMobile,
        handleMessagingAllowed,
        chatRights,
        setChatRights,
        newMessagePopup,
        handleCloseFunction,
        handleMessaging,
    }

    return (
        <>
            {/* Desktop view */}
            <div className="columns-custom is-hidden-mobile" style={{ height: "98.5%" }}>
                {/* Conversation Favourites */}
                <ConversationFavouritesDesktop conversationFavouritesDesktopProps={conversationFavouritesDesktopProps} />

                {/* Messaging */}
                <ConversationMessagingDesktop conversationMessagingDesktopProps={conversationMessagingDesktopProps} />


                {/* Appointments */}
                <ConversationAppointments conversationAppointmentsProps={conversationAppointmentsProps} />

            </div>

            {/* Mobile View */}
            <div className="is-hidden-desktop mt-4" style={{ marginBottom: "75px", }}>

                {/* Drop Down for modules */}
                <div className='is-flex is-justify-content-space-between'
                    style={{ border: "1px solid black", borderRadius: "8px", paddingLeft: "8px", paddingTop: "5px", paddingRight: "5px", marginBottom: "10px" }}>
                    <p className='has-text-weight-semibold'>Conversational Actions</p>
                    <div className="custom-dropdown" ref={dropdownRef}>
                        <SlOptionsVertical onClick={toggleDropdown} className="dropdown-toggle" />

                        {isDropdownOpen && (
                            <ul className="dropdown-menu-custom">
                                {modulesChatSLB?.map(module => {
                                    return <li className="dropdown-item-custom" onClick={() => handleCloseModulesTab(module?.variableName)}>{module?.name}</li>
                                })}
                            </ul>
                        )}
                    </div>
                </div>

                {/* Conversation Favourites */}
                <ConversationFavouritesMobile conversationFavouritesMobileProps={conversationFavouritesMobileProps} />

                {/* Messaging */}
                <ConversationMessagingMobile conversationMessagingMobileProps={conversationMessagingMobileProps} />

                {/* Appointments */}
                <ConversationAppointments conversationAppointmentsProps={conversationAppointmentsProps} />
            </div>

        </>
    )
}

export default ConversationModules;

export const ListItem = styled.li.attrs(() => ({ className: 'p-3 row g-0 align-items-center', }))`
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    background: ${({ bgGrey, active }) => bgGrey || active ? 'rgba(0,0,0,0.04)' : 'white'};
    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.04);
    }
  `;

export const EllipsisText = styled.p.attrs(() => ({}))` 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Tag = styled.p.attrs(() => ({ className: 'tag is-right is-danger has-font-weight-bold', }))`
    width: 2em;
    height: 2em;
    border-radius: 100% !important;
    background: #06d755 !important; 
    top: 0;
    right: 0;
    margin-top: 25px;
  `;

export const ListLink = styled.a.attrs(() => ({
    className: 'has-background-white',
}))`
    &:hover {
      cursor: auto;
    }
  `;

export const ClickableText = styled(Text).attrs()`
  &:hover {
    cursor: pointer;
  }
`;