import React from 'react'
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { GiEmptyHourglass } from "react-icons/gi";
import { Icon } from '@safelyq/bulma-ui-library';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import StarRatings from 'react-star-ratings';
import { Dropdown, Space } from 'antd';
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { GrMailOption } from "react-icons/gr";
import { SlCalender } from "react-icons/sl";
import { TbVectorTriangle } from "react-icons/tb";
import { AiOutlineMessage } from "react-icons/ai";
import { HiOutlineHeart } from "react-icons/hi";

const ConversationFavouritesMobile = ({ conversationFavouritesMobileProps }) => {
    const {
        showStatus,
        setShowStatus,
        selectedConversationId,
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        handleMessagingAllowed,
        handleAppointments,
    } = conversationFavouritesMobileProps;

    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';

    const items = [

        {
            label: 'Mail',
            name: "mail",
            key: '0',
            icon: <GrMailOption />,

        },
        {
            label: 'Appointment',
            name: "appointment",
            key: '1',
            icon: <SlCalender />
        },
        {
            label: 'Quotation',
            name: "quotation",
            key: '2',
            icon: <TbVectorTriangle />
        },
        {
            label: 'Message',
            name: "message",
            key: '3',
            icon: <AiOutlineMessage />
        },
        {
            label: 'Unfavourite',
            name: "unfavourite",
            key: '4',
            icon: <HiOutlineHeart />
        },
    ];

    return (
        <div>
            <div className='is-flex is-justify-content-space-between is-clickable mb-3 custom-tab-dropdown py-1 px-2'
                onClick={() => setShowStatus({ ...showStatus, favouriteBusiness: !showStatus?.favouriteBusiness })}>
                <p className={`is-size-6 has-text-weight-semibold  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`}>
                    Favourites
                </p>
                {showStatus?.favouriteBusiness
                    ?
                    <FaChevronUp className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`} />
                    :
                    <FaChevronDown className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`} />}
            </div>

            {
                showStatus?.favouriteBusiness &&
                <div className='mb-3' style={{ overflowX: "auto", whiteSpace: "nowrap", width: "100%", scrollbarWidth: "thin" }}>
                    {
                        !selectedConversationId ?
                            <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                <GiEmptyHourglass color='gray' fontSize={26} />
                                <p className='ml-2 has-text-weight-semibold has-text-grey-dark'>No Conversation Selected</p>
                            </div>
                            :
                            favBusinesses?.length === 0
                                ?
                                <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                    <GiEmptyHourglass color='gray' fontSize={26} />
                                    <p className='ml-2 mb-5 has-text-weight-semibold has-text-grey-dark'>No Businesses Found</p>
                                </div>
                                :
                                favBusinesses?.map(({ business }, i) => {
                                    return (
                                        <div
                                            className='card-custom-businesses horizontal-business-card is-relative'
                                            key={business.id}
                                        >
                                            <div style={{ position: "relative" }}>
                                                <FavouriteButton className='is-hidden-mobile has-text-info is-clickable'>
                                                    <Icon
                                                        onClick={() =>
                                                            // This is a universal Utility Function
                                                            handleFavouriteBusinesses(business, favBusinesses, setFavBusinesses, selectedConversationId)}
                                                        name='heart' collection='fa' />
                                                </FavouriteButton>
                                                <img className='business-image-business-card'
                                                    src={business?.picture?.path || emptyThumbnail}
                                                    alt={business.name}
                                                    onError={(e) => e.target.src = emptyThumbnail}
                                                    style={{ width: "100%", objectFit: "cover" }} />
                                            </div>


                                            <div className='px-2 my-2'>
                                                <Link to={{ pathname: `/business/${business.id}` }} className='is-size-6 has-text-weight-bold has-text-black' title={business?.name}    >
                                                    {business?.name?.length <= 14 ? business?.name : `${business?.name.substring(0, 14)}...`}
                                                </Link>
                                                <div className='business-card-details'>
                                                    <span className='subtitle is-7 mr-2'>  {business?.city}  </span>
                                                    <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                                                </div>
                                            </div>

                                            {/* Message button and a custom drop down */}
                                            <div className='is-flex'>
                                                <p
                                                    className='is-clickable'
                                                    style={{
                                                        border: "1px solid #032B98", borderRadius: "22px", width: "137px", textAlign: "center", padding: "10px", height: "32px", display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    onClick={() =>
                                                        handleMessagingAllowed(
                                                            business?.id,
                                                            business?.businessSubscription?.subscriptionPlan,
                                                            business?.messaging)
                                                    }
                                                >
                                                    Message
                                                </p>

                                                <Dropdown
                                                    menu={{
                                                        items: items.map(item => ({
                                                            ...item,
                                                            onClick: () => {
                                                                if (item.name === "appointment") {
                                                                    handleAppointments(business?.id); // Call the appointment handler
                                                                } else if (item.name === "mail") {
                                                                    if (business?.email) {
                                                                        // Open mailto link
                                                                        window.location.href = `mailto:${business.email}?subject=Mail from ${business.name}`;
                                                                    } else {
                                                                        // Show warning if email is not available
                                                                        toast.warning('Email address is not available for this business.');
                                                                    }
                                                                } else if (item?.name === "message") {
                                                                    if (business?.businessSubscription?.subscriptionPlan?.features?.genericNumberMessagingAllowed) {
                                                                        return handleMessagingAllowed(business?.id, business?.businessSubscription?.subscriptionPlan, business?.messaging);
                                                                    }
                                                                    else {
                                                                        return toast.warning("This business does not accept messages at this time")
                                                                    }
                                                                } else if (item?.name === "unfavourite") {
                                                                    handleFavouriteBusinesses(business, favBusinesses, setFavBusinesses, selectedConversationId);
                                                                }
                                                            },
                                                        })),
                                                    }}
                                                    trigger={['click']}
                                                >
                                                    <p
                                                        className='ml-2'
                                                        style={{
                                                            border: "1px solid #032B98", borderRadius: "25px", width: "32px", textAlign: "center", height: "32px", display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}>

                                                        <HiOutlineDotsHorizontal />
                                                    </p>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    )
                                })

                    }
                </div>
            }

            {/* Divider */}
            <hr className='mt-3' style={{ width: '35%', margin: '10px auto', height: '2px', backgroundColor: '#afafaf', border: 'none' }} />
        </div>
    )
}

export default ConversationFavouritesMobile;

const FavouriteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 27px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;