import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { toast } from 'react-toastify';
import { BsThreeDots } from "react-icons/bs";
import { Dropdown } from 'antd';
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { GrMailOption } from "react-icons/gr";
import { SlCalender } from "react-icons/sl";
import { TbVectorTriangle } from "react-icons/tb";
import { AiOutlineMessage } from "react-icons/ai";
import { HiOutlineHeart } from "react-icons/hi";

const BusinessCardVertical = ({ businessCardVerticalProps, business }) => {
    const {
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        selectedConversationId,
        handleAppointments,
        handleMessagingAllowed
    } = businessCardVerticalProps;

    const history = useHistory();
    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';
    const items = [
        {
            label: 'Mail',
            name: "mail",
            key: '0',
            icon: <GrMailOption />,

        },
        {
            label: 'Appointment',
            name: "appointment",
            key: '1',
            icon: <SlCalender />
        },
        {
            label: 'Quotation',
            name: "quotation",
            key: '2',
            icon: <TbVectorTriangle />
        },
        {
            label: 'Message',
            name: "message",
            key: '3',
            icon: <AiOutlineMessage />
        },
        {
            label: 'Unfavourite',
            name: "unfavourite",
            key: '4',
            icon: <HiOutlineHeart />
        },
    ];

    return (
        <div
            className="column is-full-widescreen is-full-fullhd is-full-desktop is-full-tablet is-half-mobile mb-4 custom-business-card-vertical"
            style={{
                height: "70px",
            }}
        >
            <div class="columns" // Set the width and height of the card
            >
                <div class="column is-one-quarter p-2">
                    <img
                        style={{ height: "55px", width: "100%", borderRadius: "8px", objectFit: "cover" }}
                        src={business?.picture?.path || emptyThumbnail}
                        alt={business.name}
                        onError={(e) => e.target.src = emptyThumbnail}
                        className="is-clickable"
                        onClick={() => history.push(`/business/${business.id}`)}
                    />
                </div>
                <div className='column is-two-thirds'>
                    <Link to={{ pathname: `/business/${business.id}` }} className='is-size-6 has-text-weight-bold' title={business?.name}    >
                        {business?.name?.length <= 15 ? business?.name : `${business?.name.substring(0, 15)}...`}
                    </Link>
                    <div className='is-flex'>
                        <p className="business_description"> {business.description ? (`${business.description.substring(0, 10)}`) : ("---")}</p>
                        <p className='ml-2 mr-1'> • </p>
                        <div className='ml-1' style={{ color: 'rgb(26 17 186)' }}>
                            <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                        </div>
                    </div>
                </div>
                <div className='is-flex is-align-items-end pb-4'>
                    <Dropdown
                        menu={{
                            items: items.map(item => ({
                                ...item,
                                onClick: () => {
                                    if (item.name === "appointment") {
                                        handleAppointments(business?.id); // Call the appointment handler
                                    } else if (item.name === "mail") {
                                        if (business?.email) {
                                            // Open mailto link
                                            window.location.href = `mailto:${business.email}?subject=Mail from ${business.name}`;
                                        } else {
                                            // Show warning if email is not available
                                            toast.warning('Email address is not available for this business.');
                                        }
                                    } else if (item?.name === "message") {
                                        if (business?.businessSubscription?.subscriptionPlan?.features?.genericNumberMessagingAllowed) {
                                            return handleMessagingAllowed(business?.id, business?.businessSubscription?.subscriptionPlan, business?.messaging);
                                        }
                                        else {
                                            return toast.warning("This business does not accept messages at this time")
                                        }
                                    } else if (item?.name === "unfavourite") {
                                        handleFavouriteBusinesses(business, favBusinesses, setFavBusinesses, selectedConversationId);
                                    }
                                },
                            })),
                        }}
                        trigger={['click']}
                    >
                        <BsThreeDots className='is-clickable' fontSize={20} color='black' />
                    </Dropdown>
                </div>
            </div>
        </div >
    )
}

export default BusinessCardVertical;
