import { useQuery } from '@apollo/client';
import { GET_BUSINESS_RATINGS } from "./requests";
import { useParams } from 'react-router-dom';
export const useGetRatings = (businessIdConfirmCheckin) => {
    const { businessId } = useParams();
    // this business id is from confirm checkin page because component is used there also
    const id = parseInt(businessId || businessIdConfirmCheckin);
    const { loading, error, data, refetch } = useQuery(GET_BUSINESS_RATINGS, {
        variables: { id },
        // fetchPolicy: 'network-only',
        // notifyOnNetworkStatusChange: true,
    }
        ,);

    return { loading, error, data, refetch };
};

