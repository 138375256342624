import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { APIPaths } from 'services/api-provider';

const createApolloClient = (accessToken) => {

    return new ApolloClient({
        uri: APIPaths.QueryPath,  // Replace with YOUR_GRAPHQL_ENDPOINT
        cache: new InMemoryCache(),
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
};

const ApolloProviderWrapper = ({ accessToken, children }) => {
    const client = createApolloClient(accessToken);

    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    );
};

export default ApolloProviderWrapper;
