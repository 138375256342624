import React, { Fragment } from 'react';
import moment from 'moment';
import useData from '../hook/useData';
import StatusCard from '../Cards/StatusCard';
import ActionCard from '../Cards/ActionCard';
import QRCode from 'qrcode.react';
import { RatingSection } from 'features/booking-feature/src';

const statusEnum = {
  BOOKED: 'Booked',
  CONFIRMED: 'Confirmed',
  CHECKEDIN: 'CheckedIn',
  BUSINESSCHECKEDIN: 'Checked_In_By_Business',
  SERVINGNOW: 'Serving_Now',
};

const ConfirmationMessage = ({ ...props }) => {
  const { interval, // appointment time - current time
    data, onConfirm, onLocalConfirm, onCheckIn } = props;

  const {
    apptId,
    status,
    venueName,
    checkinTime,
    startTime,
    timeZone,
    confirmationOffset,
    lateCheckInTimeThreshold,
    isConfirmationRequired,
    isCheckinRequired,
    isQuestionsRequired,
    businessId,
    responses
  } = useData(data);

  const checkinCallBack = { callBack: onCheckIn };

  const confirmCallBack = {
    callBack: isQuestionsRequired ? onLocalConfirm : onConfirm,
  };

  const actualDateTime = moment.tz(checkinTime, timeZone); // checkin time (allocated time)

  // Converting the start time to the same timezone
  const startDateTime = moment.tz(startTime, timeZone);
  let isCheckinOffset = startDateTime.diff(actualDateTime, 'minutes');  // appointment time - checkin time

  // appointment time + latecheckin threshold
  const howMuchLateCanUserCheckinTime = moment(startDateTime)
    .add(lateCheckInTimeThreshold, 'minutes');

  // check if it is greater than current time
  const timePassed = howMuchLateCanUserCheckinTime.isBefore(moment().tz(timeZone));

  const isBooked = status === statusEnum.BOOKED;

  const isConfirmed = status === statusEnum.CONFIRMED;

  const isCheckedIn = status === statusEnum.CHECKEDIN;

  const isBusinessCheckedIn = status === statusEnum.BUSINESSCHECKEDIN;

  // If current time is less than or equal to the time allowed for conf before appointment
  const isConfirmOffsetMet = () => interval <= confirmationOffset;

  // If the current time is in between checkin allowed time and late checkin allowed time  
  const isCheckinOffsetMet = () =>
    interval <= isCheckinOffset && interval >= -lateCheckInTimeThreshold;



  const isGreenCard =
    isCheckinRequired &&
      isCheckinOffsetMet() &&
      ((isConfirmationRequired &&
        (isConfirmed || isCheckedIn || isBusinessCheckedIn)) ||
        (!isConfirmationRequired &&
          (isBooked || isCheckedIn || isBusinessCheckedIn)))
      ? 'success'
      : 'info';

  const RenderFlow = () => {
    if (isConfirmationRequired) {
      switch (status) {
        case statusEnum.BOOKED:
          if (!isConfirmOffsetMet()) {
            return (
              <NotConfirmedNotMet confirmationOffset={confirmationOffset} />
            );
          }

          if (isConfirmOffsetMet()) {
            return <NotConfirmedButMet {...confirmCallBack} />;
          }
          break;

        case statusEnum.CONFIRMED:
          if (!isCheckinOffsetMet()) {
            return <ConfirmedButNotMet />;
          }

          if (isCheckinOffsetMet()) {
            return <ConfirmedButMet {...checkinCallBack} />;
          }
          break;

        case statusEnum.CHECKEDIN:
          return <CheckedIn venueName={venueName} />;

        case statusEnum.BUSINESSCHECKEDIN:
          return <CheckedIn venueName={venueName} />;

        case statusEnum.SERVINGNOW:
          return <Serving_Now />;

        default:
          return <Fragment></Fragment>;
      }
    } else {
      switch (status) {
        case statusEnum.BOOKED:
          if (!isCheckinOffsetMet()) return <ConfirmedButNotMet />;

          if (isCheckinOffsetMet())
            return <ConfirmedButMet {...checkinCallBack} />;
          break;
        case statusEnum.BUSINESSCHECKEDIN:
          return <CheckedIn venueName={venueName} />;
        case statusEnum.CHECKEDIN:
          return <CheckedIn venueName={venueName} />;
        case statusEnum.SERVINGNOW:
          return <Serving_Now />;
        default:
          return <Fragment></Fragment>;
      }
    }
  };

  return (
    <Fragment>
      <StatusCard data={data} color={isGreenCard} timePassed={timePassed} />

      {
        // If the appointment is completed than show review else check if time is passed than show nothing else show Action buttons
        status === "Completed" ? (
          <RatingSection
            isLoggedIn="true"
            login={() => { }}
            businessIdConfirmCheckin={businessId}
          />
        ) : timePassed ? (
          null
        ) : (
          <RenderFlow />
        )
      }

      {
        // Confirmation messages
        responses?.length !== 0 && status === "Confirmed" && !timePassed &&
        <div className={`has-width-medium has-text-left is-size-6 notification py-3`} style={{ border: "1px solid gray" }}>
          {/* <div className={`has-width-medium has-text-left is-size-6 notification is-${isGreenCard} py-3 has-width-medium`}> */}
          <p className='is-size-5 has-text-weight-bold has-text-centered mb-2'>Confirmation Questions</p>
          {
            responses?.map((response, index) => {
              return <div>
                <p>Ques. {response?.businessQuestion?.prompt}</p>
                <p className='has-text-weight-semibold'>Ans. {response?.answer ? "Yes" : "No"}</p>
              </div>
            })
          }
        </div>
      }

      {
        // current time exists between checkin, late checkin and status is confirmed only if the confirmation is required
        isCheckinOffsetMet() && (isConfirmationRequired ? status === "Confirmed" : true) && (status !== "CheckedIn") && (
          <div className='column is-12 has-text-centered'>
            <div className='has-text-weight-medium mb-2 is-size-5'>
              Your Check-In code
            </div>
            <QRCode value={`https://safelyq.com/${apptId}/confirm-checkin`} />
          </div>
        )
      }

    </Fragment >
  );
};

const NotConfirmedNotMet = ({ confirmationOffset }) => (
  <ActionCard
    heading={`What's next ?`}
    primaryText={`You are required to confirm ${confirmationOffset} minutes before the appointment`}
    secondaryText={`A notification will be sent as a reminder`}
    btnLabel={`Confirm Appointment`}
    isActionDisabled={true}
    color={`info`}
  />
);

const NotConfirmedButMet = ({ ...rest }) => (
  <ActionCard
    heading={`What's next ?`}
    primaryText={`You are required to confirm your appointment`}
    btnLabel={`Confirm Appointment`}
    isActionDisabled={false}
    color={`info`}
    {...rest}
  />
);

const ConfirmedButNotMet = () => (
  <ActionCard
    heading={`What's next ?`}
    primaryText={`Check-In upon arrival and wait outside until further instructions`}
    btnLabel={`Check-In`}
    isActionDisabled={true}
    color={`info`}
  />
);

const ConfirmedButMet = ({ ...rest }) => (
  <ActionCard
    heading={`What's next ?`}
    primaryText={`Check-In upon arrival and wait outside until further instructions`}
    btnLabel={`Check-In Now`}
    isActionDisabled={false}
    color={`success`}
    {...rest}
  />
);

const CheckedIn = ({ venueName }) => (
  <ActionCard
    heading={`What's next ?`}
    primaryText={`Please approach the entrance now ${venueName ? `(${venueName})` : ''
      }`}
    btnLabel={`Checked In`}
    isActionDisabled={true}
    color={`success`}
  />
);

const Serving_Now = () => (
  <ActionCard
    heading={`Thank you for using SafelyQ`}
    btnLabel={`Serving Now`}
    isActionDisabled={true}
    color={`success`}
  />
);

export default ConfirmationMessage;
