import React from 'react'
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { GiEmptyHourglass } from "react-icons/gi";
import BusinessCardVertical from '../components/BusinessCardVertical';
import ClipLoader from 'react-spinners/ClipLoader';

const ConversationFavouritesDesktop = ({ conversationFavouritesDesktopProps }) => {
    const {
        showStatus,
        setShowStatus,
        selectedConversationId,
        isLoadingConFavs,
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        handleMessagingAllowed,
        handleAppointments,
        handleMessaging
    } = conversationFavouritesDesktopProps;

    const businessCardVerticalProps = {
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        selectedConversationId,
        handleAppointments,
        handleMessaging,
        setShowStatus,
        handleMessagingAllowed,
    }

    return (
        <div>
            <div
                onClick={() => setShowStatus({ ...showStatus, favouriteBusiness: !showStatus?.favouriteBusiness })}
                className='is-flex is-justify-content-space-between px-3 is-clickable my-3 py-1 custom-tab-dropdown'>
                <p
                    className={`is-size-5 has-text-weight-regular  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`}>
                    Conversation Favourites
                </p>
                <>
                    {showStatus?.favouriteBusiness
                        ?
                        <FaChevronUp
                            className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`} />
                        :
                        <FaChevronDown
                            className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`} />}
                </>
            </div>

            <div>
                {
                    showStatus?.favouriteBusiness && <div className='mb-3'>
                        {
                            !selectedConversationId ?
                                <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                    <GiEmptyHourglass color='gray' fontSize={26} />
                                    <p className='ml-2 has-text-weight-semibold has-text-grey-dark'>No Conversation Selected</p>
                                </div>
                                :
                                isLoadingConFavs
                                    ?
                                    <div style={{ height: "100%" }} className='is-flex is-justify-content-center is-align-items-center'>
                                        <ClipLoader size={28} />
                                    </div>
                                    :
                                    favBusinesses?.length === 0
                                        ?
                                        <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                            <GiEmptyHourglass color='gray' fontSize={26} />
                                            <p className='ml-2 has-text-weight-semibold has-text-grey-dark'>No Favourites Exist</p>
                                        </div>
                                        :
                                        <>
                                            <div className='is-hidden-mobile scroller-for-modules'>
                                                {
                                                    favBusinesses.map(({ business }) => (
                                                        <BusinessCardVertical businessCardVerticalProps={businessCardVerticalProps} business={business} />
                                                    ))}
                                            </div>
                                            <div className='is-hidden-desktop scroller-for-modules'>
                                                {
                                                    favBusinesses.map(({ business }) => (
                                                        <BusinessCardVertical businessCardVerticalProps={businessCardVerticalProps} business={business} />
                                                    ))}
                                            </div>
                                        </>
                        }
                    </div>
                }
            </div>

            {/* Divider */}
            <hr className='mt-3' style={{ width: '35%', margin: '10px auto', height: '2px', backgroundColor: '#afafaf', border: 'none' }} />
        </div>
    )
}

export default ConversationFavouritesDesktop