import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '@safelyq/bulma-ui-library';
import { useProfile } from 'hooks/useProfile';
import useData from '../hook/useData';
import moment from 'moment';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { getStatusBackground, getStatusColor, getStatusName } from 'methods/getStatuses';

const statusEnum = {
  BOOKED: 'Booked',
  CONFIRMED: 'Confirmed',
  CHECKEDIN: 'CheckedIn',
};

const StyledButton = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 5px;
  margin-right: 5px;
`;

const StatusCard = ({ data, color, timePassed }) => {


  const {
    apptId,
    status,
    adults,
    startTime,
    checkinTime,
    appointmentTime,
    timeZone,
    serviceName,
    sublevelName,
    venueName,
    businessId,
    isConfirmationRequired,
    confirmationOffset,
    specialInstructions,
    lateCheckInTimeThreshold,
  } = useData(data);
  const { profile } = useProfile();

  const isBooked = () => status === statusEnum.BOOKED;

  const isConfirmed = () => status === statusEnum.CONFIRMED;

  const isCheckedIn = () => status === statusEnum.CHECKEDIN;

  const isGuestsAvailable = () => adults > 0;

  // Parsing start time and end time in correct format and converting into the timezone 
  const formattedStartTime = moment.tz(data?.startTimeOnly, 'h:mm A', timeZone).format('HH:mm');
  const formattedEndTime = moment.tz(data?.endTime, timeZone).format('HH:mm');

  return (
    <div className={`notification is-${color} py-3 has-width-medium`}>
      <div className=''>
        <StyledButton
          to={`/business/${businessId}?edit-appointment=${apptId}`}
          className='button is-info is-small is-light'
        >
          <Icon name={`${status === "Passed" || status === "CheckedIn" || status === "Completed" || status === "Cancelled" || timePassed ? "eye" : "pencil"}`} />
        </StyledButton>
      </div>
      {/* Appointment Status: {status} */}
      <span
        className={`tag is-light`}
        style={{ background: "white", border: `1px solid rgb(${getStatusColor(status)})`, color: `rgb(${getStatusColor(status)})`, }}>
        {getStatusName(status)}
        {/* {timePassed ? "Passed" : getStatusName(status)} */}
      </span>
      <div className='content is-small'>
        <h3 className='has-text-white mt-2'>
          {profile.email} {isGuestsAvailable() && `${adults} Total Guests`}
        </h3>
        <strong>
          Your appointment has been {isBooked() && 'set'}
          {(isConfirmed() || isCheckedIn()) && 'confirmed'} on
        </strong>
        <h3 className='mt-0 mb-1 has-text-white has-text-weight-bold'>
          {moment.tz(appointmentTime, timeZone).format('dddd MMMM Do, yyyy')} at{' '}
          {moment.tz(appointmentTime, timeZone).format('hh:mm A')}
        </h3>

        <div className='is-flex is-justify-content-center'>
          <span className='mr-2' style={{ fontStyle: 'italic', color: 'white' }}>Time Zone:</span>
          <span>{timeZone}</span>
        </div>

        <strong>{sublevelName || serviceName}</strong>
        <br />

        {
          // If time is passed than dont shwo status and show message
          timePassed || status === "Passed" || status === "Completed" || status === "Cancelled" ? (
            <strong>Looking forward to serving you again</strong>
          ) : (
            <>
              {status === "Booked" && (
                <strong>
                  {isConfirmationRequired ? "Confirm on:" : "Check-In:"}
                  <span className="ml-2">
                    {isConfirmationRequired
                      ? moment.tz(startTime, timeZone)
                        .subtract(confirmationOffset, "minutes")
                        .format("hh:mm A") // Confirmation time before appointment
                      : moment.tz(checkinTime || startTime, timeZone).format("hh:mm A")} {/* If Check-In time does not exist than start time is checkin time */}
                  </span>
                </strong>
              )}

              {status === "Confirmed" && (
                <strong>
                  Check-In:
                  <span className="ml-2">
                    {checkinTime
                      ? moment.tz(checkinTime, timeZone).format("hh:mm A") // If check-in time exists
                      : moment.tz(startTime, timeZone).format("hh:mm A")} {/* Else show appointment time */}
                  </span>
                </strong>
              )}

              {status === "CheckedIn" && <strong>Checked In</strong>}
            </>
          )
        }


        {/* <strong>Check-In: {moment(checkinTime).format('hh:mm A')}</strong> */}
        {venueName && <strong> at {venueName}</strong>}
        <p className='is-marginless'>
          {
            // Appointment is not checkin and neither passed neither completed neither cancelled, and also time passed is false, only then show calender
            (status !== "CheckedIn" && status !== "Passed" && status !== "Cancelled" && status !== "Completed" && !timePassed)
            &&
            specialInstructions && specialInstructions
          }
        </p>
        {
          // Appointment is not checkin and neither passed neither completed neither cancelled, and also time passed is false, only then show calender
          (status === "CheckedIn" || status === "Passed" || status === "Cancelled" || status === "Completed" || timePassed) ?
            ""
            : (
              <div className='mt-1' style={{ display: "flex", justifyContent: "center" }}>
                <AddToCalendarButton
                  name={`${data?.business?.name} - ${data?.subBusiness?.name}`}
                  description={data?.business?.description}
                  startDate={moment.tz(data?.startTime, timeZone).format("YYYY-MM-DD")}
                  startTime={formattedStartTime}
                  endTime={formattedEndTime}
                  location={data.business.address1}
                  options={['Google', 'Apple', 'Outlook.com', 'MicrosoftTeams', 'Microsoft365', 'Yahoo', 'iCal']}
                  timeZone={data?.timeZone}
                ></AddToCalendarButton>
              </div>
            )}
      </div>
    </div >
  );
};

export default StatusCard;
