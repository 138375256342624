import { useLazyQuery, useMutation } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import {
    GET_BUSINESS_PAYMENT_DATA,
    GET_STRIPE_PUBLISHABLE_KEY,
    STRIPE_COLLECT_BUSINESS_PAYMENT,
} from './requests';

export const useData = (setIsPaymentCompleted, setEmailStatuses, setAutoPaymentLoading, setPaymentReciept) => {

    // get stripe publishable key
    const [onGetStripePublishableKey, { error, loading: isLoading, data }] = useLazyQuery(
        GET_STRIPE_PUBLISHABLE_KEY,
        {
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
        }
    );

    // get dataId if already not used
    const [onGetBusinessPaymentData, { error: businessDataError, loading: businessDataLoading, data: businessData }] = useLazyQuery(
        GET_BUSINESS_PAYMENT_DATA,
        {
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
        }
    );


    // Post Collect Business Payment
    const [
        onCollectBusinessPayment,
        { loading: loadingBusinessPayment },
    ] = useMutation(STRIPE_COLLECT_BUSINESS_PAYMENT, {
        onCompleted: ({
            collectBusinessPayment: {
                isSaved,
                errorMessage,
                emailExists,
                isPaymentCompleted,
                receiptUrl
            },
        }) => {
            if (isSaved && isPaymentCompleted) {
                setIsPaymentCompleted(true)
                showToast.success('Stripe transaction is completed successfully.');
                setPaymentReciept(receiptUrl)
            } else if (emailExists) {
                setEmailStatuses(prevStatuses => ({
                    ...prevStatuses,
                    emailExists: emailExists
                }));

                setAutoPaymentLoading(false);
                showToast.error(errorMessage || "Could not proceed with the transaction");
            }
        },
        onError: ({ message }) => {
            showToast.error(message || 'Could not proceed with the transaction');

        },
    });

    const { getStripePublishableKey } = data || {};
    const { isSuccess, publishableKey } = getStripePublishableKey || {};

    return {
        isLoading,
        error,
        isSuccess,
        publishableKey,
        loadingBusinessPayment,
        onCollectBusinessPayment,
        onGetStripePublishableKey,
        onGetBusinessPaymentData,
        businessDataError,
        businessDataLoading,
        businessData
    };
};
