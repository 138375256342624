import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { Icon } from '@safelyq/bulma-ui-library';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { Dropdown } from 'antd';
import { GrMailOption } from "react-icons/gr";
import { SlCalender } from "react-icons/sl";
import { TbVectorTriangle } from "react-icons/tb";

const BusinessCardHoritonzal = ({ businessCardHorizontalProps, business }) => {
    const {
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        selectedConversationId,
        handleAppointments,
        handleMessaging,
        getFavoriteIconColor,
        showStatus,
        setShowStatus,
        setChatRights
    } = businessCardHorizontalProps;

    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';

    const items = [

        {
            label: 'Mail',
            name: "mail",
            key: '0',
            icon: <GrMailOption />,

        },
        {
            label: 'Appointment',
            name: "appointment",
            key: '1',
            icon: <SlCalender />
        },
        {
            label: 'Quotation',
            name: "quotation",
            key: '2',
            icon: <TbVectorTriangle />
        },
    ];


    // Fav Screen
    const handleFavourite = (business, favBusinesses, setFavBusinesses, selectedConversationId) => {
        handleFavouriteBusinesses(business, favBusinesses, setFavBusinesses, selectedConversationId);
        setShowStatus({ ...showStatus, favouriteBusiness: true, messaging: false, appointments: false }) // close all other sections
    }

    const handleBusinessSelected = (business) => {

        handleMessaging(business?.id);

        setChatRights({
            messagingAllowed: business?.messaging?.messagingAllowed,
            consumerInitiationAllowed: business?.messaging?.consumerInitiationAllowed,
            consumerResponseAllowed: business?.messaging?.consumerResponseAllowed,
            consumerAttachmentsAllowed: business?.messaging?.consumerAttachmentsAllowed,
        })
    }

    const { allMessages } = useSelector((state) => state.auth);

    const handleMessagingAllowed = () => {
        let conversationStarted = allMessages.find((chat) => chat?.business?.id === business?.id);

        // check if subscribed
        if (business?.businessSubscription?.subscriptionPlan?.features?.genericNumberMessagingAllowed) {
            // check if new conversation
            if (conversationStarted) {
                business?.messaging?.consumerResponseAllowed // check if response allowed
                    ?
                    handleBusinessSelected(business)
                    :
                    toast.warning("This business does not accept messages at this time");
            } else {
                business?.messaging?.consumerInitiationAllowed ? // check if initiation allowed
                    handleBusinessSelected(business)
                    :
                    toast.warning("This business does not accept messages at this time");
            }
        } else {
            toast.warning("This business does not accept messages at this time");
        }
    };

    return (

        // Previous fav business div
        <div
            className='card-custom-businesses horizontal-business-card is-relative'
            key={business.id}
        >
            <div style={{ position: "relative" }}>
                <FavouriteButton className='is-hidden-mobile has-text-info is-clickable'>
                    <Icon
                        onClick={() =>
                            // This is a universal Utility Function
                            handleFavourite(business, favBusinesses, setFavBusinesses, selectedConversationId)}
                        name={getFavoriteIconColor(business?.id)} collection='fa' />
                </FavouriteButton>
                <img className='business-image-business-card'
                    src={business?.picture?.path || emptyThumbnail}
                    alt={business.name}
                    onError={(e) => e.target.src = emptyThumbnail}
                    style={{ width: "100%", objectFit: "cover" }} />
            </div>


            <div className='px-2 my-2'>
                <Link to={{ pathname: `/business/${business.id}` }} className='is-size-6 has-text-weight-bold has-text-black' title={business?.name}    >
                    {business?.name?.length <= 14 ? business?.name : `${business?.name.substring(0, 14)}...`}
                </Link>
                <div className='business-card-details'>
                    <span className='subtitle is-7 mr-2'>  {business?.city}  </span>
                    <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                </div>
            </div>

            {/* Message button and a custom drop down */}
            <div className='is-flex'>
                <p
                    className='is-clickable'
                    style={{
                        border: "1px solid #032B98", borderRadius: "22px", width: "137px", textAlign: "center", padding: "10px", height: "32px", display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    onClick={handleMessagingAllowed}
                >
                    Message
                </p>

                <Dropdown
                    menu={{
                        items: items.map(item => ({
                            ...item,
                            onClick: () => {
                                if (item.name === "appointment") {
                                    handleAppointments(business?.id); // Call the appointment handler
                                } else if (item.name === "mail") {
                                    if (business?.email) {
                                        // Open mailto link
                                        window.location.href = `mailto:${business.email}?subject=Mail from ${business.name}`;
                                    } else {
                                        // Show warning if email is not available
                                        toast.warning('Email address is not available for this business.');
                                    }
                                }
                            },
                        })),
                    }}
                    trigger={['click']}
                >
                    <p
                        className='ml-2'
                        style={{
                            border: "1px solid #032B98", borderRadius: "25px", width: "32px", textAlign: "center", height: "32px", display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>

                        <HiOutlineDotsHorizontal />
                    </p>
                </Dropdown>
            </div>
        </div>
    )
}


export default BusinessCardHoritonzal;

const FavouriteButton = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background: white;
  width: 31px;
  height: 31px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;
