import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { RxHamburgerMenu } from "react-icons/rx";
import { IoSend } from "react-icons/io5";
import Businesses from './Businesses';
import PriorSection from './PriorSection';
import { GiEmptyHourglass } from "react-icons/gi";
import ClipLoader from 'react-spinners/ClipLoader';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getAvatarName } from 'utils/getAvatarName';

// HTMl conversion
const parseMarkdown = (text) => {
    // Replace multiple new lines with a single one
    text = text.replace(/\n{2,}/g, '\n\n');
    // Bold text
    let html = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    // Italic text
    html = html.replace(/\*([^*]+)\*/g, '<em>$1</em>');
    // Strikethrough text
    html = html.replace(/~~(.*?)~~/g, '<del>$1</del>');
    // Underlined text
    html = html.replace(/__([^_]+)__/g, '<u>$1</u>');
    // Lists (both unordered and ordered)
    html = html.replace(/(?:^|\n)\* (.*?)(?=\n|$)/g, '<ul><li>$1</li></ul>'); // Unordered lists
    html = html.replace(/(?:^|\n)\d+\. (.*?)(?=\n|$)/g, '<ol><li>$1</li></ol>'); // Ordered lists
    // Remove any extra closing tags for lists
    html = html.replace(/<\/ul>\s*<ul>/g, '');
    html = html.replace(/<\/ol>\s*<ol>/g, '');
    // Code blocks (multiline code)
    html = html.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>');
    // Inline code
    html = html.replace(/`([^`]+)`/g, '<code>$1</code>');
    // Links
    html = html.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');
    // Images
    html = html.replace(/\!\[([^\]]*)\]\(([^)]+)\)/g, '<img src="$2" alt="$1" />');
    // New lines (convert to <br> tags)
    html = html.replace(/\n/g, '<br/>');
    // Remove remaining Markdown characters
    html = html.replace(/(\*\*|__|\*|~~|`|\[\[|\]\]|\[|\]|!|`|\\)/g, '');
    // Ensure proper list closing tags
    html = html.replace(/<\/ul><ul>/g, '');
    html = html.replace(/<\/ol><ol>/g, '');
    // Return the cleaned and formatted HTML
    return html;
};

const MainSectionAI = ({ mainSectionProps }) => {
    const {
        setUserStatement,
        setShowInput,
        setMessages,
        showInput,
        input,
        messages,
        handleSend,
        setInput,
        loading,
        isLoadingConversationMsgs,
        chatSLBTextData,
        isLoadingText,
        favBusinesses,
        setFavBusinesses,
        handleFavouriteBusinesses,
        selectedBusinessForMessage,
        setSelectedBusinessForMessage,
        showStatus,
        setShowStatus,
        messagingDivRef,
        messagingDivRefMobile,
        selectedBusinessForAppointment,
        setSelectedBusinessForAppointment,
        previousConversation,
        selectedConversationId,
        onUpdateConversationName,
        setPreviousConversations,
        onSelectChat,
        setSelectedConversationId,
        scrollableDivMessagesRef,
        newMessagePopup,
        setNewMessagePopup,
        showMessagingScreens,
        setShowMessagingScreens,
        allList,
        showAppointmentsScreens,
        setShowAppointmentsScreens,
        paginationForMsgs,
        setPaginatingForMsgs,
        chatRights,
        setChatRights,
        handleDeleteConversation,
    } = mainSectionProps;


    const customMessageDivRef = useRef(null);
    const messagesEndRef = useRef(null); // responsible for sending the view to the last message
    const [showFullConversationTitle, setShowFullConversationTitle] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [scrollHeightState, setScrollHeightState] = useState(0);
    const { userinfo } = useSelector((state) => state.auth);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [editingChat, setEditingChat] = useState(null); // Track which chat is being edited
    const [title, setTitle] = useState(''); // Track the title of the chat being edited

    // This is a closure proprety and holds the initially stated value
    // when the value changes, it does not update
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault(); // Optional: Prevent default behavior
                handleSend();
            }
        };

        // Add event listener on mount
        document.addEventListener('keydown', handleKeyDown); // trigger send input on enter press

        // Cleanup event listener on unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []); // Empty dependency array ensures this effect runs once on mount

    // Scroll to the bottom whenever messages change or new mesage is typed 
    // since we have row reversed in style, so we would use block end instead of start
    // Only scroll to the bottom if we're not paginating
    useEffect(() => {
        if (!paginationForMsgs && messagesEndRef.current) {

            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }

        // Reset the pagination flag after adding messages
        setPaginatingForMsgs(false);

    }, [messages]);

    // change the indicator pointing based on the view of the page
    useEffect(() => {
        const element = customMessageDivRef.current;
        const handleScroll = () => {

            if (element) {

                const { scrollTop, scrollHeight, clientHeight } = element;
                setScrollHeightState(scrollHeight)
                setIsAtBottom(scrollTop <= 1); // At the bottom
                setIsOverflowing(scrollHeight > clientHeight)
            }
        };

        if (customMessageDivRef.current) {
            customMessageDivRef.current.addEventListener('scroll', handleScroll);
            // Initial check
            handleScroll();
        }

        return () => {
            if (customMessageDivRef.current) {
                customMessageDivRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [messages]);

    const handleSendMessage = (value) => {
        setInput(value);
    }

    // Custom Drop Down that closes when clicked anywhere other on the screen
    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Update the Conversation Name
    const handleEditClick = (chat) => {
        setEditingChat(chat);  // Set the chat being edited
        setTitle(chat?.title);       // Initialize the title with the chat's current title
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleBlur = () => {
        // Handle save logic if needed
        setEditingChat(null);  // Stop editing when the input loses focus
    };

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            // Handle save logic if needed
            setEditingChat(null);  // Stop editing when 'Enter' is pressed

            const result = await onUpdateConversationName({
                variables: {
                    conversationId: editingChat?.id,
                    title: title
                }
            })

            // If successfull than change the name in the state
            if (result?.data?.updateChatSLBConversation?.isSaved) {
                const dummy = previousConversation?.map(conversation => {
                    if (conversation?.id === editingChat?.id) {
                        const newConversation = { ...conversation, title: title };
                        return newConversation
                    }
                    return conversation
                });

                setPreviousConversations(dummy)
            }

        }
    };

    const getDisplayTitle = () => {
        const selectedConversation = previousConversation?.find(conversation => conversation?.id === selectedConversationId);
        if (!selectedConversation?.title) return '';
        return showFullConversationTitle
            ? selectedConversation.title // Show full title when clicked
            : selectedConversation.title.length > 20
                ? `${selectedConversation.title.slice(0, 20)}...` // Truncate if longer than 20 characters
                : selectedConversation.title; // Show full title if less than 20 chars
    };

    const handleNewChat = () => {
        setShowInput(false);
        setMessages([]);
        setSelectedConversationId(0);
        setFavBusinesses([])
    }

    const setRefs = (element) => {

        customMessageDivRef.current = element;
        scrollableDivMessagesRef.current = element;
    };

    // Props
    const businessProps = {
        selectedBusinessForMessage,
        setSelectedBusinessForMessage,
        selectedBusinessForAppointment,
        setSelectedBusinessForAppointment,
        showStatus,
        setShowStatus,
        messagingDivRef,
        messagingDivRefMobile,
        selectedConversationId,
        handleFavouriteBusinesses,
        messages,
        loading,
        setFavBusinesses,
        favBusinesses,
        newMessagePopup,
        setNewMessagePopup,
        showMessagingScreens,
        setShowMessagingScreens,
        allList,
        showAppointmentsScreens,
        setShowAppointmentsScreens,
        chatRights,
        setChatRights
    };

    const priorSectionProps = {
        chatSLBTextData,
        isLoadingText,
        input,
        loading,
        handleSend,
        setInput,
        setUserStatement,
        setShowInput,
        setMessages,
        messages
    };

    return (
        <div className="main-section-custom">

            {/* Header for Desktop */}
            <div className="is-hidden-mobile header-custom is-flex is-justify-content-center">
                {
                    // Selected Conversation for desktop only
                    previousConversation?.find(conversation => conversation?.id === selectedConversationId) &&
                    <div className='is-hidden-mobile'>
                        <small className='is-size-6'>
                            {previousConversation?.find(conversation => conversation?.id === selectedConversationId)?.title}
                        </small>
                        <small className='is-size-7 has-text-grey ml-2'>
                            Started At: {moment(previousConversation?.find(conversation => conversation?.id === selectedConversationId)?.createdTime).format('MMMM Do YYYY, h:mm:ss')}
                        </small>
                    </div>
                }
            </div>

            {/* Header for Mobile */}
            <div className="is-hidden-desktop header-custom is-flex is-justify-content-space-between">
                {/* Dropdown menu for mobile */}
                <div className="custom-dropdown is-hidden-desktop pt-2" ref={dropdownRef}>
                    <div className='setting-icon-custom' onClick={toggleDropdown} >
                        <RxHamburgerMenu />
                    </div>

                    {isDropdownOpen && (
                        <>
                            <ul className="dropdown-menu-custom pl-2" style={{ left: "280%" }}>
                                <li onClick={() => handleNewChat()}>
                                    <a className='is-flex has-text-black is-size-6 mb-2'>
                                        <FiPlus className='mt-1 mr-2' /> New Chat
                                    </a>
                                </li>
                                {previousConversation.map(chat => (
                                    <li key={chat?.id} className='mb-1' style={{ textAlign: "start" }}>
                                        <a className='is-flex has-text-black is-size-6'>
                                            {editingChat?.id === chat?.id ? ( // Check if this chat is being edited
                                                <input
                                                    type="text"
                                                    value={title}
                                                    onChange={handleTitleChange}
                                                    onBlur={handleBlur}
                                                    onKeyDown={handleKeyDown}
                                                    autoFocus
                                                    className="input is-small"
                                                />
                                            ) : (
                                                <small
                                                    onClick={() => onSelectChat(chat)}
                                                    className={`is-clickable ${selectedConversationId === chat?.id && "has-text-weight-bold is-underlined has-text-black"}`}
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        display: 'block',
                                                    }}
                                                >
                                                    {chat?.title}
                                                </small>
                                            )}
                                            <span className="edit-icon ml-2">
                                                <MdOutlineModeEditOutline color='black' onClick={() => handleEditClick(chat)} />
                                                <MdOutlineDeleteOutline color='red'
                                                    onClick={() => handleDeleteConversation(chat?.id)}
                                                />
                                            </span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </>

                    )}
                </div>

                <div className='is-hidden-desktop has-text-justified'>
                    <small className='is-size-6' onClick={() => setShowFullConversationTitle(!showFullConversationTitle)}> {getDisplayTitle()}</small>
                    {
                        // Selected Conversation for mobile only
                        previousConversation?.find(conversation => conversation?.id === selectedConversationId) &&
                        <p className='is-size-7 has-text-grey'>
                            Started At:
                            {moment(previousConversation?.find(conversation => conversation?.id === selectedConversationId)?.createdTime).format('MMMM Do YYYY, h:mm:ss')}
                        </p>
                    }
                </div>
            </div>

            {/* Main Section - same for both */}
            {
                <div className="chat-container-custom" style={{}}>
                    <div className="messages-custom" ref={setRefs}>
                        {
                            // Places at the top , because column is not reversed
                            isLoadingConversationMsgs && <div className='mt-3 p-1 px-5 is-flex mx-auto'
                                style={{
                                    border: "1px solid gray", borderRadius: "12px"
                                }}>
                                <div style={{ height: "100%" }} className='is-flex is-justify-content-center is-align-items-center'>
                                    <ClipLoader size={20} color='gray' className="" />
                                </div>
                                <p className='ml-2'> Loading...</p>
                            </div>}

                        {
                            showInput ?
                                <>
                                    {
                                        // reverse the to show from bottom up
                                        messages?.slice().reverse().map((message, index) => {
                                            let htmlContent, businesses;
                                            if (message?.text) {
                                                let text = message?.text;
                                                // if (message?.sender === "user") {
                                                //     text = text.replace(/\n/g, '');  // Removes any literal backslashes followed by 'n'
                                                // }
                                                htmlContent = parseMarkdown(text)

                                            }
                                            if (message?.businesses?.length !== 0) {
                                                businesses = message.businesses;
                                            }

                                            return (
                                                <div
                                                    key={`${message.id}-${index}`}
                                                    ref={index === messages.length - 1 ? messagesEndRef : null}
                                                    style={{

                                                        width: "100%",
                                                        marginLeft: message.sender === "ai" ? "0px" : "auto",
                                                        marginRight: message.sender === "user" ? "0px" : "auto"
                                                    }}
                                                >
                                                    <div
                                                        className={`message-content is-flex ${message.sender === "ai" ? "is-justify-content-start" : "is-justify-content-end"}`}
                                                        style={{ alignItems: 'center' }} // To vertically center the icon and message
                                                    >
                                                        {message.sender === "ai" && <p className='slb-message-icon'>SLB</p>} {/* FS icon for AI messages */}

                                                        {
                                                            // show the response if exist
                                                            message?.text && (
                                                                <div
                                                                    className={`message-custom`}
                                                                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                                                                    style={{
                                                                        margin: '0 0px',
                                                                        borderRadius: "20px",
                                                                        backgroundColor: message?.sender === "user" ? "#F8F9FF" : "white",
                                                                        width: "max-content"
                                                                    }} // Add margin for spacing between the icon and text
                                                                />
                                                            )
                                                        }

                                                        {message.sender === "user" && <p className='user-message-icon ml-2'> {getAvatarName(userinfo)}</p>} {/* FS icon for User messages */}
                                                    </div>

                                                    {
                                                        // Show business if exist
                                                        businesses?.length === 0 && messages?.length > 1 && !loading ? (
                                                            <div className='is-flex is-justify-content-center my-1' style={{ width: "100%" }}>
                                                                <GiEmptyHourglass color='gray' fontSize={26} />
                                                                <p className='ml-2 is-size-5 has-text-weight-semibold has-text-grey-dark'>No Suggestions Found</p>
                                                            </div>
                                                        ) : businesses && (
                                                            <Businesses businessProps={businessProps} businesses={businesses} />
                                                        )
                                                    }
                                                </div>

                                            )
                                        })}
                                </>
                                :
                                <PriorSection priorSectionProps={priorSectionProps} />
                        }
                    </div>
                    {isOverflowing && (
                        <div style={{ position: "absolute", bottom: 50, right: 20 }}>
                            <div className='has-text-right' style={{ cursor: 'pointer' }}>
                                {isAtBottom
                                    ?
                                    <div onClick={() => customMessageDivRef.current.scrollTo({ top: customMessageDivRef.current.scrollHeight, behavior: 'smooth' })}>⏬</div>
                                    :
                                    <div onClick={() => customMessageDivRef.current.scrollTo({ top: 0, behavior: 'smooth' })}> ⏫ </div>}
                            </div>
                        </div>
                    )}

                </div>
            }
            <div className="input-box-custom">
                <input
                    type="text"
                    value={input}
                    onChange={e => handleSendMessage(e.target.value)}
                    placeholder="Type a message..."
                />
                {
                    loading
                        ?
                        <ClipLoader size='20' style={{ marginTop: "auto", marginBottom: "auto" }} />
                        :
                        <IoSend color='#1F1E1E' size={20} className='is-clickable' onClick={handleSend} style={{ marginTop: "auto", marginBottom: "auto" }} />
                }
            </div>
        </div>
    )
}

export default MainSectionAI