import React from 'react'
import { GiEmptyHourglass } from "react-icons/gi";
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { toggleActiveChat } from 'Redux/AuthSlice/AuthSlice';
import BusinessCardHoritonzal from './BusinessCardHorizontal';

const Businesses = ({ businessProps, businesses }) => {

    const {
        setSelectedBusinessForMessage,
        setSelectedBusinessForAppointment,
        showStatus,
        setShowStatus,
        messagingDivRef,
        selectedConversationId,
        handleFavouriteBusinesses,
        setFavBusinesses,
        favBusinesses,
        newMessagePopup,
        setNewMessagePopup,
        showMessagingScreens,
        setShowMessagingScreens,
        allList,
        showAppointmentsScreens,
        setShowAppointmentsScreens,
        chatRights,
        setChatRights
    } = businessProps;
    const dispatch = useDispatch();

    const getFavoriteIconColor = (businessId) => {
        const isFavorite = favBusinesses?.find(fav => fav?.business?.id === businessId);
        return isFavorite ? "heart" : "heart-o";
    };

    const handleMessaging = (id) => {

        // Send the view to the messaging div
        if (messagingDivRef.current) {
            messagingDivRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        // Scroll for mobile
        // if (messagingDivRefMobile.current) {
        //     messagingDivRefMobile.current.scrollIntoView({ behavior: 'smooth' });
        // }

        // If the message is not found in the list than show modal for display
        if (!allList?.find(conversation => conversation?.business?.id === id)) {
            setNewMessagePopup({
                status: true,
                businessId: id,
            })
            setShowMessagingScreens({ ...showMessagingScreens, modal: true, list: true });
            // list is true because else empty chat screen will be shown that cant be closed
        }

        setSelectedBusinessForMessage(id);

        setShowStatus({ ...showStatus, messaging: true, favouriteBusiness: false, appointments: false }) // close all other sections
        handleActiveChat(id);
    }

    const handleAppointments = (id) => {
        setShowStatus({ ...showStatus, favouriteBusiness: false, messaging: false, appointments: true }) // close all other sections
        setShowAppointmentsScreens({ appointments: true, list: false })
        setSelectedBusinessForAppointment(id)
    }

    const handleActiveChat = (chatid) => {
        dispatch(toggleActiveChat({ businessId: chatid }))
    }

    const businessCardHorizontalProps = {
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        selectedConversationId,
        handleAppointments,
        handleMessaging,
        getFavoriteIconColor,
        showStatus,
        setShowStatus,
        newMessagePopup,
        setNewMessagePopup,
        showAppointmentsScreens,
        setShowAppointmentsScreens,
        chatRights,
        setChatRights
    }


    return (
        <>
            {/* Sliding businesses view in the main section */}
            <div className='my-4' style={{
                overflowX: "auto",
                whiteSpace: "nowrap",
                width: "100%",
                scrollbarWidth: "thin"
            }}>
                {
                    businesses?.length === 0
                        ?
                        <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                            <GiEmptyHourglass color='gray' fontSize={26} />
                            <p className='ml-2 mb-5 has-text-weight-semibold has-text-grey-dark'>No Suggestions Found</p>
                        </div>
                        :

                        businesses?.map((business, i) => {
                            return (

                                <BusinessCardHoritonzal businessCardHorizontalProps={businessCardHorizontalProps} business={business} />
                            )
                        })
                }
            </div>
        </>
    )
}

export default Businesses

const FavouriteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 27px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;