import { gql } from '@apollo/client';

export const GET_STRIPE_PUBLISHABLE_KEY = gql`
  query all {
    getStripePublishableKey {
      isSuccess
      errorMessage
      publishableKey
  }
}
`;

// for testing 
export const STRIPE_TRANSACTION_REQUEST = gql`
  mutation ($description: String, $tokenId: String, $amount: Decimal) {
  invokeStripeTransactionRequest(description: $description, tokenId: $tokenId, amount: $amount) {
    isSaved
    errorMessage
  }
}
`;

export const STRIPE_COLLECT_BUSINESS_PAYMENT = gql`
  mutation collectBusinessPayment($collectBusinessPaymentInput: CollectBusinessPaymentInput!) {
    collectBusinessPayment(collectBusinessPaymentInput: $collectBusinessPaymentInput) {
      receiptUrl
      url
      isSaved
      isPaymentCompleted
      errorMessage
      emailExists
      receiptUrl
    }
  }
`;

export const GET_BUSINESS_PAYMENT_DATA = gql`
query getData($dataId: String){
	getBusinessPaymentData(dataId: $dataId){
		isSuccess
		errorMessage
		
		businessId
		dataId
		phoneNumber
		receiptUrl
		amount
	}
}
`