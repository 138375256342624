import React, { useEffect } from 'react';
import { Box, Content, Heading } from '@safelyq/bulma-ui-library';
import StarRatings from 'react-star-ratings';
import { AddNewRating, RatingList } from './components';
import { useGetRatings } from './hooks';
import { Skeleton } from 'antd'
import { useSelector } from 'react-redux';
import firestore from '@firebase';
import { useLocation } from 'react-router-dom';

export const RatingSection = (props) => {
  const { isLoggedIn, login: tryToLogin, persistStorage, businessIdConfirmCheckin } = props;
  const { loading, error, data, refetch } = useGetRatings(businessIdConfirmCheckin);

  const { authinfo, userinfo } = useSelector((state) => state.auth);
  const { pathname } = useLocation();


  useEffect(() => {

    const unsubscribe = firestore.collection('ratings').where('customerId', '==', `${userinfo.id}`).onSnapshot(() => {
      if (String(pathname).includes('/business')) {
        refetch()
      }
    });
    return () => unsubscribe();
  }, [userinfo.id, refetch, pathname]);

  if (loading) {
    return (<Skeleton.Input style={{ height: '200px', }} active={true} size={"large"} block={true} />)
  }
  else if (data) { // if api response is undefined than dont return anything 
    const { getBusinessById: { id, serviceRatings, userRatings, name } } = data
    return (
      <Box style={{
        width: pathname.includes("confirm-checkin") ? "450px" : "auto",  // Apply width conditionally
      }}>
        <Content className='p-2'>
          <Heading size='5' className='mb-2'>
            Rating & Reviews
          </Heading>
          <div className='is-flex is-justify-content-center' style={{ color: 'rgb(26 17 186)' }}  >
            <StarRatings rating={serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='1px' starDimension='15px' />
            {/* <StarRatings rating={serviceRatings?.reviewsCount} starRatedColor='rgb(26 17 186)' starSpacing='1px' starDimension='15px' /> */}
            <span className='ml-3'>[ {serviceRatings?.reviewsCount} ]</span>
          </div>
          {
            !pathname.includes("confirm-checkin") // does not show this section in the confirm checkout section
            &&
            <>
              <hr className='my-3' />
              {[5, 4, 3, 2, 1].map((value, index) => (
                <div key={`rating-stars-${index}`} className='is-flex is-justify-content-space-between' style={{ color: 'rgb(26 17 186)' }}   >
                  <StarRatings rating={value} starRatedColor='rgb(26 17 186)' numberOfStars={value} starSpacing='1px' starDimension='15px' />
                  <span> {userRatings.filter((rating) => Math.ceil(rating.value) === value).length}  </span>
                </div>
              ))}
              <hr className='my-3' />
            </>
          }

          {/* {authinfo ? <AddNewRating businessId={id} refetch={refetch} /> : <p>You must <span style={{ color: 'rgb(26 17 186)' }} >Logged In</span> to post a review</p>} */}
          <AddNewRating businessId={id} refetch={refetch} isLoggedIn={isLoggedIn} tryToLogin={tryToLogin} />
          {authinfo ? <RatingList userRatings={userRatings} businessName={name} /> : <p>You must <span style={{ color: 'rgb(26 17 186)' }} >Logged In</span> to post a review</p>}
        </Content>
      </Box>
    );
  }

};
