import React, { useState, useRef } from 'react'
import { HeroSection, StyledMessageLoader } from '../ManageUserMessages.styled'
import MessageBoxMetaTags from '../MetaTags/MessageBoxMetaTags'
import { useLazyQuery } from '@apollo/client';
import { GET_BUSINESS_CONVERSATION } from '../requests';
import { useEffect } from 'react';
import MessageInput from './MessageInput/MessageInput';
import { useDispatch, useSelector } from 'react-redux';
import { updateSingleChatUnreadCount } from 'Redux/AuthSlice/AuthSlice';
import MessageHeader from './MessageHeader/MessageHeader';
import MessageBody from './MessageBody/MessageBody';
import NoChat from '../../../../../../assets/no_chat.png';
import { ClipLoader } from 'react-spinners';
import firestore from '@firebase';
import { useLocation } from 'react-router-dom';

const MessageBox = (props) => {
  const { businessIdFromSLB, selectedConversationId, chatRights, setChatRights } = props;
  const [messagesPagination, setMessagesPagination] = useState({
    skip: 0,
    take: 20
  });

  // const [totalMessagesCount, setTotalMessagesCount] = useState(0);
  // console.log("totalMessagesCount:", totalMessagesCount);

  const totalMessagesCount = useRef(0);

  const [allchats, setAllChats] = useState([])

  const { pathname } = useLocation();

  const [onGetBusinessConversation, { loading }] = useLazyQuery(GET_BUSINESS_CONVERSATION, { fetchPolicy: 'no-cache', });

  const { allMessages, userinfo } = useSelector((state) => state.auth);

  let activeChat = allMessages.find((chat) => chat.active)

  if (!activeChat) {
    activeChat = { business: { id: 0 } }
  }

  const dispatch = useDispatch()

  // refetch is called after the data message is sent
  const getNewMessage = async () => {

    try {
      const response = await onGetBusinessConversation({
        variables: {
          businessId: activeChat.business.id,
          conversationId: pathname === "/search-business-ai" ? selectedConversationId : 0,
          ...messagesPagination
        }
      })

      // setTotalMessagesCount(response?.data?.getBusinessConversation?.totalCount)
      totalMessagesCount.current = response?.data?.getBusinessConversation?.totalCount
      setAllChats(response.data.getBusinessConversation.messages)
      if (response.data.getBusinessConversation.messages.length > 0) {
        const singleChat = response.data.getBusinessConversation.messages[0]
        setChatRights({
          messagingAllowed: singleChat.business.messaging.messagingAllowed,
          consumerResponseAllowed: singleChat.business.messaging.consumerResponseAllowed,
          consumerAttachmentsAllowed: singleChat.business.messaging.consumerAttachmentsAllowed,
        })
        dispatch(updateSingleChatUnreadCount({ businessid: response.data.getBusinessConversation.messages[0].business.id, unreadCount: response.data.getUser.user.conversationsSummary.unreadCount }))
      }
    } catch (error) {
    }
  }

  useEffect(() => {

    const unsubscribe = firestore.collection('chat').where('customerId', '==', `${userinfo.id}`).where('businessId', '==', `${activeChat?.business.id}`).onSnapshot(async querySnapshot => {

      // This fetches dynamic data from messages as well in chatslb
      if (String(pathname).includes('/messages') || String(pathname).includes('/search-business-ai')) {

        if (activeChat?.business.id) {
          // if called from chatslb page, than send conversation id else send null
          const response = await onGetBusinessConversation({
            variables: {
              businessId: activeChat.business.id,
              conversationId: pathname === "/search-business-ai" ? selectedConversationId : 0,
              ...messagesPagination
            }
          })

          totalMessagesCount.current = response?.data?.getBusinessConversation?.totalCount
          setAllChats(response?.data?.getBusinessConversation?.messages)
          if (response?.data?.getBusinessConversation?.messages?.length > 0) {
            const singleChat = response.data.getBusinessConversation.messages[0]
            const selectedConvoFromGlobalState = allMessages?.find(convoBusiness => convoBusiness?.business?.id === activeChat?.business.id)
            // Fetch chat rights from global state

            pathname !== "/search-business-ai" && setChatRights({
              messagingAllowed: selectedConvoFromGlobalState?.business?.messaging?.messagingAllowed,
              consumerInitiationAllowed: selectedConvoFromGlobalState?.business?.messaging?.consumerInitiationAllowed,
              consumerResponseAllowed: selectedConvoFromGlobalState?.business?.messaging?.consumerResponseAllowed,
              consumerAttachmentsAllowed: selectedConvoFromGlobalState?.business?.messaging?.consumerAttachmentsAllowed,
            })

            dispatch(updateSingleChatUnreadCount({
              businessid: response.data.getBusinessConversation.messages[0].business.id,
              unreadCount: response.data.getUser.user.conversationsSummary.unreadCount
            }))
          }
        }
      }
    });
    return () => unsubscribe();
  }, [activeChat.business.id, dispatch, onGetBusinessConversation, pathname, userinfo.id])

  // Messagign Pagination
  const messageListRef = useRef(null);

  useEffect(() => {
    const fetchBusinessConversation = async () => {
      if (messagesPagination?.skip > 0) {
        try {
          const response = await onGetBusinessConversation({
            variables: {
              businessId: activeChat.business.id,
              conversationId: pathname === "/search-business-ai" ? selectedConversationId : 0,
              ...messagesPagination
            }
          });

          if (response?.data) {
            // Handle the data properly here
            // Process the response as needed

            totalMessagesCount.current = response?.data?.getBusinessConversation?.totalCount
            if (allchats?.length > 0) {
              setAllChats([...allchats, ...response?.data?.getBusinessConversation?.messages]);
            }
            else {
              setAllChats(response.data.getBusinessConversation.messages)
            }
          } else {
            // console.error("Error: No data found in response");
          }

        } catch (error) {
          // console.error("Error fetching conversation:", error);
        }
      }
    };

    fetchBusinessConversation();
  }, [messagesPagination, activeChat.business.id, pathname, selectedConversationId, onGetBusinessConversation]);


  const loadMoreMessages = () => {
    setMessagesPagination((prev) => {
      // Check if the total number of messages has been reached
      if (prev.skip + prev.take >= totalMessagesCount.current) {
        ;
        return prev; // Return the previous state without changes
      }

      // Return the updated state
      return {
        skip: prev.skip + 20, // Increment the skip by 20
        take: 20 // Keep take as 20 for every fetch
      };
    });
  };

  const handleScroll = () => {
    const messageList = messageListRef.current;
    const { scrollTop, scrollHeight, clientHeight } = messageList;

    // In reverse column, scrollTop is negative or 0, so we check when it's close to the maximum scroll.
    const isScrolledToTop = Math.abs(scrollTop) + clientHeight >= scrollHeight - 1; // Adjust for 1px

    if (isScrolledToTop) {
      loadMoreMessages();
    }
  };

  return (
    <>
      <HeroSection style={{ scrollbarWidth: "none" }}>
        {
          activeChat.business.id ? (
            <>
              <MessageBoxMetaTags title={activeChat?.business.name} />
              <MessageHeader activeChat={activeChat} allchats={allchats} />
              <MessageBody
                allchats={allchats}
                loading={loading}
                messagesPagination={messagesPagination}
                setMessagesPagination={setMessagesPagination}
                onGetBusinessConversation={onGetBusinessConversation}
                handleScroll={handleScroll}
                messageListRef={messageListRef}
              />
              {loading && <StyledMessageLoader >

                <div className='container has-text-centered'>
                  <ClipLoader size='40' />
                  <br />
                  <small>Loading please wait...</small>
                </div>
              </StyledMessageLoader>}

              <MessageInput
                activeChat={activeChat}
                selectedConversationId={selectedConversationId}
                getNewMessage={getNewMessage}
                allowedMessage={chatRights.messagingAllowed}
                allowedResponse={chatRights.consumerResponseAllowed}
                allowedAttatchment={chatRights.consumerAttachmentsAllowed}
              />
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <img src={NoChat} alt="nochat" />
            </div>
          )
        }
      </HeroSection>
    </>

  )
}

export default MessageBox


const FullScreenLoader = () => (
  <section className='hero is-fullheight'>
    <div className='hero-body'>
      <div className='container has-text-centered'>
        <ClipLoader size='40' />
        <br />
        <small>Loading please wait...</small>
      </div>
    </div>
  </section>
);


