import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AdminRoutes } from './AdminRoutes';
import { SuperAdminRoutes } from './SuperAdminRoutes';
import { UserRoutes } from './UserRoutes';
import { useProfile } from 'hooks/useProfile';
import { useDispatch } from 'react-redux';
import { adduser } from 'Redux/AuthSlice/AuthSlice';
import FullPageLoader from 'sharedComponents/FullPageLoader/FullPageLoader';
import { useQuery } from '@apollo/client';
import Queries from 'components/Common/Queries';
import { NotFound } from 'components/NotFound';
import { setBusinessCategory } from 'Redux/BusinessSlice/Business';
import { AdminLayout } from 'features/base-feature/src';
import { useLocation } from 'react-router-dom';

const Routes = () => {

  const { pathname } = useLocation();

  const { loading, data } = useQuery(Queries.GET_USER_IS_PROFILE_COMPLETE);

  const profile = useProfile();
  const { login, logut } = useProfile();

  // redirect to the login and than home if the user is already logged in
  const loggedInLocal = localStorage.getItem("authPersist")
  const isLoggingInForPayment = localStorage.getItem("userLoggingInForPayment") === "true"; // payment: fetching email via login

  useEffect(() => {
    if (loggedInLocal === "true") {
      // do not allow auto login unless user clicks on the login on payment screen
      if (pathname === "/payment") {
        isLoggingInForPayment && login(); // user has clicked on the login
      }
      else {
        login();
      }

    }
  }, [])

  // update persist true
  useEffect(() => {
    if (profile?.oidcUser?.access_token) {
      localStorage.setItem("authPersist", "true")
    }
  }, [profile?.oidcUser?.access_token])

  const dispatch = useDispatch()
  if (data) {
    const userdata = {
      id: data.getUser.user?.id,
      email: data.getUser.user?.email,
      firstName: data.getUser.user?.firstName,
      lastName: data.getUser.user?.lastName,
      name: data.getUser.user?.name,
      isSuperAdmin: data.getUser.user?.isSuperAdmin,
      totalNotifications: data.isProfileComplete.unviewedCount,
      unreadCount: data.getUser.user?.conversationsSummary.unreadCount,
      // unreadCount: 0,
      isAuthenticated: data.isProfileComplete.isAuthenticated,
      isCompleted: data.isProfileComplete.isCompleted,
      isPhoneNumberConfirmed: data.isProfileComplete.isPhoneNumberConfirmed,
    }
    const obj = {
      authinfo: profile.oidcUser,
      userinfo: {
        ...userdata
      }
    }
    dispatch(adduser(obj))
  }

  // const obj = {
  //   authinfo: profile.oidcUser,

  // }
  // dispatch(adduser(obj))




  return (
    <div>
      {loading && <FullPageLoader />}
      <Switch>
        <Route path={'/admin'} component={() =>
          <AdminRoutes profile={profile} />
        }
        />
        {/* <Route path={'/admin'} component={() =>
          <AdminLayout>
            <AdminRoutes profile={profile} />
          </AdminLayout>
        }
        /> */}
        <Route path={'/superadmin'} component={() => <SuperAdminRoutes profile={profile} data={data} />} />
        <UserRoutes profile={profile} />
        <NotFound path={'/notfound'} />
      </Switch>
    </div>

  );
};

export default Routes;
