import React, { useState } from 'react';
import { Icon, Box, Title, Field, Control, Label, Buttons, Button, DateInput, Content } from '@safelyq/bulma-ui-library';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { GET_BUSINESS_BY_ID } from '../../routes/ManageBooking/requests';
import { useParams, useHistory, Link, useLocation } from 'react-router-dom';
import { Skeleton } from 'antd';
import NewAppointmentSlots from './NewAppointmentSlots';
import { Spin } from 'antd';
import { CREATE_USER_APPOINTMENT } from './requests';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_RECAPTCHA_PUBLIC_KEY } from './constant';
import { MdBusinessCenter } from "react-icons/md";
import DatePicker from 'react-datepicker';

const NewAppointment = (props) => {
    const { isLoggedIn, login: tryToLogin, persistStorage, isAllowToCreate, businessIdByChatSLB, business, selectedConversationId, handleCloseAppointmentSection } = props;

    const { pathname } = useLocation();
    const [apptInfo, setApptInfo] = useState({
        date: moment().format("YYYY-MM-DD"),
        service: "",
        serviceProvider: "",
        children: "",
        adults: "",
        startTimeOnly: "",
        startTime: "",
        endTime: "",
        notes: ""
    })

    const [checkForHuman, setCheckForHuman] = useState(false);
    const [allServiceProviders, setAllServiceProviders] = useState([])
    const [selectedSlot, setSelectedSlot] = useState({
        startTimeOnly: "",
        startTime: "",
        endTime: "",
    })
    const [loader, setLoader] = useState(false)

    let { businessId } = useParams();


    const { push } = useHistory();
    const business_Id = businessId ? parseInt(businessId) : parseInt(businessIdByChatSLB);


    const [onUpdate] = useMutation(CREATE_USER_APPOINTMENT);
    const dispatch = useDispatch()

    const { loading, error, data, refetch, } = useQuery(GET_BUSINESS_BY_ID, { variables: { id: business_Id } })

    const canUserCreateAppointment = data?.getBusinessById?.canUserCreateAppointment;



    const currentSlot = { startTimeOnly: "", startTime: "", endTime: "", subBusiness: "", };

    function handleGoogleCaptcha(value) {


        // If value exist then set appointment
        value && handleUpdate();
    }

    const handleAppointmentInfoChange = (e) => {
        let { name, value } = e.target
        if (name === "service") {
            const all_SP = data.getBusinessById.services.find((service) => service.id === parseInt(value))?.businesses
            if (all_SP) {
                if (all_SP.length > 0) {

                    setAllServiceProviders(all_SP)
                    setApptInfo((prev) => ({
                        ...prev,
                        serviceProvider: all_SP[0].id
                    }))
                }
                else {
                    setAllServiceProviders([])
                    setApptInfo((prev) => ({
                        ...prev,
                        serviceProvider: ""
                    }))
                }
            } else {
                setAllServiceProviders([])
                setApptInfo((prev) => ({
                    ...prev,
                    serviceProvider: ""
                }))
            }
        }
        setApptInfo((prev) => ({
            ...prev,
            [name]: value
        }))


        // Empty the selected slot only on these
        if (name === "service" || name === "adults" || name === "serviceProvider" || name === "date") {
            setSelectedSlot({
                startTimeOnly: "",
                startTime: "",
                endTime: "",
            })
        }
    }

    const handleSlotChange = (slot) => {
        setSelectedSlot({
            startTimeOnly: slot.startTimeOnly,
            startTime: moment(slot.startTime).utc().format('YYYY-MM-DD hh:mm A'),
            endTime: moment(slot.endTime).utc().format('YYYY-MM-DD hh:mm A'),
        })

    }

    const handleUpdate = async () => {
        const subBusinessId = apptInfo.serviceProvider ? parseInt(apptInfo.serviceProvider) : data.getBusinessById.id

        // Pathname check ensures persist flow runs only on the appointment screen, as this component is also used in the chatslb screen
        if (!isLoggedIn && pathname !== "/search-business-ai") {
            const pData = {
                apptInfo: apptInfo,
                selectedSlot: selectedSlot,
                timeZone: data.getBusinessById.timeZone,
                subBusinessId: subBusinessId,
                businessId: data.getBusinessById.id
            }
            persistStorage(pData);
            await tryToLogin();
            return;
        }

        const { service, serviceProvider, children, adults, notes } = apptInfo;

        localStorage.removeItem('persist-data');

        try {

            setLoader(true)
            const response = await onUpdate({
                variables: {
                    appointmentInput: {
                        adults: parseInt(adults),
                        children: children === "yes" ? 1 : 0,
                        notes: notes,
                        startTime: selectedSlot.startTime,
                        endTime: selectedSlot.endTime,
                        timeZone: data.getBusinessById.timeZone,
                        serviceId: parseInt(service),
                        businessId: data.getBusinessById.id,
                        subBusinessId: subBusinessId,
                        userResponses: [],
                        chatSLBConversationId: selectedConversationId
                    },
                },
            })

            if (response.data.createUserAppointment.errorMessage) {
                setCheckForHuman(false);
                const errorMessage = { code: 403, message: response.data.createUserAppointment.errorMessage };
                throw errorMessage;
            }
            toast.success("Appointment Created successfully")

            // Do not redirect if the page is ChatSLB
            if (pathname === "/search-business-ai") {
                handleCloseAppointmentSection(); // close the appointment and show list in chatslb
            }
            else {
                push(`/${response.data.createUserAppointment.appointment.id}/confirm-checkin`);
            }

            setLoader(false)
        } catch (error) {
            setLoader(false)
            toast.error(error.message);
        }

    }

    useEffect(() => {
        (async () => {
            const apptData = JSON.parse(localStorage.getItem('persist-data'));
            if (apptData) {
                try {
                    const { service, serviceProvider, children, adults, notes } = apptData.apptInfo;
                    setLoader(true)
                    const response = await onUpdate({
                        variables: {
                            appointmentInput: {
                                adults: parseInt(adults),
                                children: children === "yes" ? 1 : 0,
                                notes: notes,
                                startTime: apptData.selectedSlot.startTime,
                                endTime: apptData.selectedSlot.endTime,
                                timeZone: apptData.timeZone,
                                serviceId: parseInt(service),
                                businessId: apptData.businessId,
                                subBusinessId: apptData.subBusinessId,
                                userResponses: [],
                            },
                        },
                    })

                    if (response.data.createUserAppointment.errorMessage) {
                        const errorMessage = { code: 403, message: response.data.createUserAppointment.errorMessage };
                        throw errorMessage;
                    }
                    toast.success("Appointment Created successfully")
                    push(`/${response.data.createUserAppointment.appointment.id}/confirm-checkin`);
                    setLoader(false)
                    localStorage.removeItem('persist-data');
                } catch (error) {
                    setLoader(false)
                    toast.error(error.message);
                }
            }
        })();
    }, [])

    if (loading) {
        return (<Skeleton.Input style={{ height: '200px', }} active={true} size={"large"} block={true} />)
    }
    else {
        const business = data.getBusinessById
        const isGuestsAllowed = business.maxAllowedGuests > 0;
        return (
            <Box bordered >
                <div className='columns is-multiline'>
                    <div className='column is-full mb-4'>
                        <Content>
                            {
                                // Show only in the chatslb section
                                pathname === "/search-business-ai"
                                &&
                                <div className='is-flex'>
                                    <MdBusinessCenter fontSize={22} />
                                    <p className='has-text-weight-bold ml-2 mb-3'>{business?.name}</p>
                                </div>
                            }
                            <div className='icon-text is-align-items-center'>
                                <Icon name='calendar-check-o' collection='fa'></Icon>
                                <h5 className='is-marginless'>New Appointment</h5>
                            </div>
                        </Content>
                    </div>

                    {canUserCreateAppointment ? (<>
                        <div
                            className={`column ${pathname === "/search-business-ai" ? "is-full is-flex is-justify-content-center" : "is-full-mobile is-half-desktop mb-4 "}`}>
                            <DatePicker
                                onChange={(date) => handleAppointmentInfoChange({
                                    target: {
                                        name: 'date', // Custom name
                                        value: moment(date).format("YYYY-MM-DD") // The selected date as the value
                                    }
                                })}
                                open={true} // Always keeps the date picker open
                                showPopperArrow={false} // Remove the arrow if desired
                                inline // Display the date picker inline (this prevents it from being a popup)
                            />
                        </div>

                        <div
                            className={`column ${pathname === "/search-business-ai" ? "is-full" : "is-hidden"}`}>
                            <Field style={{ borderRadius: "12px" }}>
                                <Control>
                                    <Label>Service</Label>
                                    <div className='select' style={{ width: "100%" }}>
                                        <select
                                            name='service'
                                            className='is-fullwidth' // is-fullwidth class is generally used for making elements like buttons or inputs take up the full width of their container not divs
                                            style={{ borderRadius: "12px", fontSize: "14px", height: "38px", color: "#797878", width: "100%" }}
                                            value={apptInfo.service} onChange={handleAppointmentInfoChange}  >
                                            <option value={''}>Select Service</option>
                                            {business.services.map(({ id, name }) => (<option key={id} value={id}> {name}   </option>))}
                                        </select>
                                    </div>
                                </Control>
                            </Field>
                        </div>

                        {allServiceProviders.length > 0 && (
                            <div className={`column ${pathname === "/search-business-ai" ? "is-full" : "is-hidden is-full mb-4"}`}>
                                <Field>
                                    <Control>
                                        <Label>Service Provider</Label>
                                        <div className='select is-fullwidth' style={{ width: "100%" }}>
                                            <select name='serviceProvider'
                                                style={{ borderRadius: "12px", fontSize: "14px", height: "38px", color: "#797878", width: "100%" }}
                                                value={apptInfo.serviceProvider} onChange={handleAppointmentInfoChange}  >
                                                <option value={''}>Select Service</option>
                                                {allServiceProviders.map((sp) => <option value={sp.id} key={sp.id}>{sp.name}</option>)}
                                            </select>
                                        </div>
                                    </Control>
                                </Field>
                            </div>
                        )}


                        <div className={`column ${pathname === "/search-business-ai" ? "is-full" : "mb-4 is-full-mobile is-half-desktop"}`}>
                            <NewAppointmentSlots
                                apptInfo={apptInfo}
                                service={apptInfo.service}
                                serviceProvider={apptInfo.serviceProvider}
                                date={apptInfo.date}
                                selectedSlot={selectedSlot}
                                currentSlot={currentSlot}
                                onChangeSlot={handleSlotChange}
                                timeZone={business.timeZone}
                                businessIdByChatSLB={businessIdByChatSLB}
                                allServiceProviders={allServiceProviders} />
                        </div>

                        <div
                            className={`column ${pathname === "/search-business-ai" ? "is-hidden is-full" : "mb-2 is-full-mobile is-two-fifths-desktop"}`}>
                            <Field style={{ borderRadius: "12px" }}>
                                <Control>
                                    <Label>Service</Label>
                                    <div className='select' style={{ width: "100%" }}>
                                        <select
                                            name='service'
                                            className='is-fullwidth' // is-fullwidth class is generally used for making elements like buttons or inputs take up the full width of their container not divs
                                            style={{ borderRadius: "12px", fontSize: "14px", height: "38px", color: "#797878", width: "100%" }}
                                            value={apptInfo.service} onChange={handleAppointmentInfoChange}  >
                                            <option value={''}>Select Service</option>
                                            {business.services.map(({ id, name }) => (<option key={id} value={id}> {name}   </option>))}
                                        </select>
                                    </div>
                                </Control>
                            </Field>
                        </div>

                        <div
                            className={`column ${pathname === "/search-business-ai" ? "is-full" : "mb-2 is-full-mobile is-three-fifths-desktop"}`}>
                            <Label size='small'>How many additional guests in your party?</Label>
                            <div className='columns is-multiline is-flex'>
                                <div className={`column ${pathname === "/search-business-ai" ? "is-half-mobile" : "is-half-desktop is-full-mobile"}`}>
                                    {isGuestsAllowed && (
                                        <Field>
                                            <div className='select is-fullwidth'>
                                                <select name='adults'
                                                    style={{ borderRadius: "12px", fontSize: "14px", height: "38px", color: "#797878" }}
                                                    value={parseInt(apptInfo.adults)} onChange={handleAppointmentInfoChange}   >
                                                    <option value={''}>No of Guests</option>  {[...Array(business.maxAllowedGuests)].map((_, index) => (<option key={`guest-${index}`} value={index + 1}>   {index + 1}  </option>))}
                                                </select>
                                            </div>
                                        </Field>
                                    )}
                                </div>

                                <div className={`column ${pathname === "/search-business-ai" ? "is-half-mobile" : "is-half-desktop is-full-mobile"}`}>
                                    {business.isChildrenAllowed ? (
                                        <Field>
                                            <div className='select is-fullwidth'>
                                                <select name='children'
                                                    style={{ borderRadius: "12px", fontSize: "14px", height: "38px", color: "#797878" }}
                                                    value={apptInfo.children} onChange={handleAppointmentInfoChange}   >
                                                    <option value={''}>Children</option>
                                                    <option value={'yes'}>Yes</option>
                                                    <option value={'no'}>No</option>
                                                </select>
                                            </div>
                                        </Field>
                                    ) : (
                                        <div className={`mt-5 ${pathname === "/search-business-ai" ? "is-full" : "is-half"}`}>
                                            <Field>  <Title size='6'>  <Icon name='times' color='danger' />  Adults only  </Title> </Field>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>) : (
                        <section>
                            <span>
                                <center>
                                    This business does not allow guest to create appointments. Please
                                    <Link to={`/messages/${business.id}`}> message</Link> the business
                                    to schedule an appointment.
                                </center>
                            </span>
                        </section>
                    )}

                    {canUserCreateAppointment ? (<>
                        {allServiceProviders.length > 0 && (
                            // <div className='col-lg-6  mb-4'>
                            <div className={`column ${pathname === "/search-business-ai" ? "is-hidden is-full" : "is-full mb-4"}`}>
                                <Field>
                                    <Control>
                                        <Label>Service Provider</Label>
                                        <div className='select is-fullwidth' style={{ width: "100%" }}>
                                            <select name='serviceProvider'
                                                style={{ borderRadius: "12px", fontSize: "14px", height: "38px", color: "#797878", width: "100%" }}
                                                value={apptInfo.serviceProvider} onChange={handleAppointmentInfoChange}  >
                                                <option value={''}>Select Service</option>
                                                {allServiceProviders.map((sp) => <option value={sp.id} key={sp.id}>{sp.name}</option>)}
                                            </select>
                                        </div>
                                    </Control>
                                </Field>
                            </div>
                        )}

                        <div className={`column ${pathname === "/search-business-ai" ? "is-full" : "is-full mb-4"}`}>
                            <Field>
                                <Label size='small' for='notes'>  Notes   </Label>
                                <textarea
                                    style={{ borderRadius: "12px" }}
                                    placeholder='Write a message here'
                                    id='notes'
                                    name='notes'
                                    value={apptInfo.notes}
                                    className='textarea'
                                    onChange={handleAppointmentInfoChange}
                                ></textarea>
                            </Field>
                        </div>

                        <div className={`column ${pathname === "/search-business-ai" ? "is-full" : "is-full mb-4"}`}>
                            <Spin spinning={loader}>
                                <Buttons size='small' style={{ justifyContent: "center" }}>
                                    <Button
                                        style={{
                                            backgroundColor: "#032B98",
                                            width: "180px",
                                            height: "48px",
                                            border: "2px solid #032B98",
                                            padding: "4px 12px",
                                            borderRadius: "8px",
                                            color: "white",
                                            fontSize: "14px",
                                            textAlign: "center"
                                        }}
                                        disabled={!selectedSlot.startTime}
                                        onClick={() => setCheckForHuman(true)}
                                    >   Set Appointment</Button>
                                </Buttons>
                            </Spin>
                        </div>

                        {
                            checkForHuman
                            &&
                            <div className='column is-full mb-4 is-flex is-justify-content-center'>
                                <ReCAPTCHA
                                    sitekey={GOOGLE_RECAPTCHA_PUBLIC_KEY}
                                    onChange={handleGoogleCaptcha}
                                />
                            </div>
                        }

                    </>) : (
                        <section>
                            <span>
                                <center>
                                    This business does not allow guest to create appointments. Please
                                    <Link to={`/messages/${business.id}`}> message</Link> the business
                                    to schedule an appointment.
                                </center>
                            </span>
                        </section>
                    )}
                </div>
            </Box>
        )
    }

}

export default NewAppointment