import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader';

const PriorSection = ({ priorSectionProps }) => {
    const {
        chatSLBTextData,
        isLoadingText,
        setInput,
        setShowInput,
    } = priorSectionProps;

    const handleOptionsSelected = (message) => {
        setShowInput(true);
        setInput(message);
    }
    return (
        isLoadingText
            ?
            <div style={{ height: "100%" }} className='is-flex is-justify-content-center is-align-items-center'>
                <ClipLoader size={28} />
            </div>
            :
            <div className='is-flex custom-align-prior-section px-2' style={{ height: "100%" }}>
                <div className='py-3 is-flex is-flex-direction-column is-justify-content-space-between' style={{ height: '100%', width: "100%" }}>
                    <p className='prior-section-heading is-fullwidth has-text-black has-text-weight-normal mb-3'>
                        {chatSLBTextData?.title || "Hi"}
                        <br />
                        {chatSLBTextData?.subTitle}
                    </p>
                    <div style={{ maxWidth: "fit-content", marginLeft: "auto" }}>
                        {
                            chatSLBTextData?.suggestions?.map((option, index) => {
                                return <p
                                    onClick={() => handleOptionsSelected(option)}
                                    className='ml-auto p-3 px-4 mb-2 is-clickable'
                                    style={{ borderRadius: "8px", backgroundColor: "#F8F9FF", color: "#032B98", width: "max-content", textAlign: "right" }}>
                                    {option}
                                </p>
                            })
                        }

                    </div>
                </div>
            </div >
    )
}

export default PriorSection