import React, { useState } from 'react'
import StripeComponent from './components/StripeCheckoutPage'
import { Container, Section } from 'libraries/bulma-ui-library'
import FetchAccessToken from './components/Authentication'
import ApolloProviderWrapper from './components/CustomAppoloProvider'
import "./style.css";

const PaymentHomePage = () => {
    const [accessToken, setAccessToken] = useState(null);

    return (
        <Container>
            <Section>
                <div>
                    <FetchAccessToken setAccessToken={setAccessToken} />
                    {
                        accessToken &&
                        <ApolloProviderWrapper accessToken={accessToken}>
                            <StripeComponent
                                accessToken={accessToken}
                            />
                        </ApolloProviderWrapper>
                    }

                </div>
            </Section>
        </Container>
    )
}

export default PaymentHomePage