import { useEffect, useState, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_AVAILABLE_APPOINTMENTS } from './requests';

export const useSlots = ({ service, appointmentDate, subLevelIds, adults }) => {
  const isMounted = useRef(false);

  const [slots, setSlots] = useState([]);

  const { businessId: business } = useParams();

  const [onRequestSlots, { loading: isLoading, error }] = useLazyQuery(
    GET_AVAILABLE_APPOINTMENTS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        service,
        appointmentDate,
        business,
        appointmentWindow: 'DAY',
        subBusinesses: subLevelIds,
      },
      onCompleted: ({ getAvailability: slots }) => {
        let dumpArray = [];
        const hasOtherSlotsAvailable = !isEmpty(slots.others);
        if (!isEmpty(slots.first)) dumpArray.push(slots.first);
        if (!isEmpty(slots.second)) dumpArray.push(slots.second);
        if (!isEmpty(slots.third)) dumpArray.push(slots.third);
        if (!isEmpty(slots.fourth)) dumpArray.push(slots.fourth);
        if (!isEmpty(slots.fifth)) dumpArray.push(slots.fifth);
        if (hasOtherSlotsAvailable) dumpArray = dumpArray.concat(slots.others);
        const uniqueSlots = Array.from(new Set(dumpArray.map(a => (a.startTimeOnly + ':' + a.subBusiness.id))))
          .map(refkey => {
            return dumpArray.find(a => (a.startTimeOnly + ':' + a.subBusiness.id) === refkey)
          });
        setSlots(uniqueSlots);
      },
    }
  );

  useEffect(() => {
    const persistData = localStorage.getItem('persist-data');

    const hasDataPersist = JSON.parse(persistData);

    if (isMounted.current || hasDataPersist) onRequestSlots();
    else isMounted.current = true;
  }, [service, appointmentDate, adults]);

  return {
    isLoading,
    error,
    data: slots,
  };
};
