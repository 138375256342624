import React, { useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { GiEmptyHourglass } from "react-icons/gi";
import { Button, List, Text, Menu, Paragraph } from '@safelyq/bulma-ui-library';
import styled from 'styled-components';
import moment from 'moment';
import { IoClose } from "react-icons/io5";
import { getStatusColor, getStatusName } from '../components/statuses';
import NewAppointment from 'features/booking-feature/src/components/NewAppointment/NewAppointment';
import EditAppointmentPopup from 'features/booking-feature/src/components/ExistingAppointment/components/EditAppointmentPopup/EditAppointmentPopup';
import firestore from '@firebase';
import { useSelector } from 'react-redux';

const ConversationAppointments = ({ conversationAppointmentsProps }) => {
    const {
        showStatus,
        setShowStatus,
        selectedConversationId,
        showAppointmentsScreens,
        handleCloseAppointmentSection,
        selectedBusinessForAppointment,
        conversationAppointments,
        handleAppointmentClicked,
        editAppointmentId,
        handleCloseAppointmentModal,
        updateCommentsInTheState,
        refetchConversationAppointments,
        getConversationAppointments
    } = conversationAppointmentsProps;


    const { userinfo } = useSelector((state) => state.auth);

    const yesterday = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');

    useEffect(() => {
        if (selectedConversationId) {
            const unsubscribe = firestore.collection('comments').where('customerId', '==', `${userinfo.id}`).onSnapshot(() => {
                getConversationAppointments({ variables: { conversationId: parseInt(selectedConversationId), startDate: yesterday } }) // Get Conversation Appointments
            });
            return () => unsubscribe();
        }
    }, [selectedConversationId, userinfo?.id, refetchConversationAppointments]);

    return (
        <div>
            <div className='is-flex is-justify-content-space-between is-clickable mb-3 custom-tab-dropdown py-1 appointment-heading'
                onClick={() => setShowStatus({ ...showStatus, appointments: !showStatus?.appointments })}>
                <p className={`is-hidden-mobile is-size-5 ${showStatus?.appointments ? "has-text-black" : "has-text-grey"}`}>
                    Appointments / Reservations
                </p>
                <p className={`is-hidden-desktop is-size-6 has-text-weight-semibold ${showStatus?.appointments ? "has-text-black" : "has-text-grey"}`}>
                    Appointments / Reservations
                </p>
                {showStatus?.appointments
                    ?
                    <FaChevronUp className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.appointments ? "has-text-black" : "has-text-grey"}`} />
                    :
                    <FaChevronDown className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.appointments ? "has-text-black" : "has-text-grey"}`} />}
            </div>

            <div>
                {showStatus?.appointments ? (
                    selectedConversationId === 0 ? (
                        <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                            <GiEmptyHourglass color='gray' fontSize={26} />
                            <p className='ml-2 has-text-weight-semibold has-text-grey-dark'>No Conversation Selected</p>
                        </div>
                    ) : (
                        <>
                            {/* Check if the "list" appointments screen should be shown */}
                            <div className='scroller-for-modules'>
                                <Menu.List>
                                    {showAppointmentsScreens?.list && (
                                        <div className='mb-3 mx-3'>
                                            {
                                                conversationAppointments?.length === 0 ?
                                                    <Paragraph alignment='centered' weight='bold'>
                                                        <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                                            <GiEmptyHourglass color='gray' fontSize={26} />
                                                            <p className='ml-2 has-text-weight-semibold has-text-grey-dark'>No Appointments Exist</p>
                                                        </div>
                                                    </Paragraph>
                                                    :
                                                    conversationAppointments?.map((appt, index) => {
                                                        return (
                                                            <List.Item key={appt.id} className="mb-4" onClick={() => handleAppointmentClicked(appt)}>
                                                                <ListLink className='is-flex is-justify-content-space-between is-align-items-center' style={{ borderRadius: "12px" }}>
                                                                    <ClickableText
                                                                    >
                                                                        <b>
                                                                            <small>  {moment.tz(appt?.startTime, appt?.timeZone).format('ddd MMM Do, yyyy')}   </small>
                                                                            <small className='ml-3'>  {moment.tz(appt?.startTime, appt?.timeZone).format('hh:mm A')}     </small>
                                                                        </b>
                                                                        <br />
                                                                        <small> {appt?.service?.name} - {appt?.business?.name}</small>
                                                                    </ClickableText>
                                                                    <span className={`is-right`} style={{ color: `rgb(${getStatusColor(appt?.status)})`, }}   >
                                                                        <strong>
                                                                            <small>{getStatusName(appt?.status)}</small>
                                                                        </strong>
                                                                    </span>
                                                                </ListLink>
                                                            </List.Item>
                                                        )
                                                    })}

                                            {
                                                /*Edit Appointment Modal */
                                                editAppointmentId?.modal &&
                                                editAppointmentId?.appointment &&
                                                <EditAppointmentPopup
                                                    isOpen={editAppointmentId.modal}
                                                    onClose={handleCloseAppointmentModal}
                                                    appt={editAppointmentId.appointment}
                                                    business={editAppointmentId?.appointment?.business}
                                                    conversationAppointmentsFromChatSLB={conversationAppointments}
                                                    updateCommentsInTheState={updateCommentsInTheState}
                                                />
                                            }
                                        </div>
                                    )}
                                </Menu.List>
                            </div>

                            {/* Check if the chat screen should be shown */}
                            {showAppointmentsScreens?.appointments && (
                                <div style={{ position: "relative", height: "120vh" }}>
                                    <Button
                                        style={{
                                            position: 'absolute',
                                            top: '6%',
                                            right: '8%',
                                            background: 'rgba(0,0,0,0.04)',
                                        }}
                                        onClick={() => handleCloseAppointmentSection()}
                                        className='icon-button action-btn p-0 m-0 mx-1'
                                        aria-haspopup='true'
                                        aria-controls='actions-dropdown'
                                    >
                                        <IoClose data-tip="Close messaging section" />
                                    </Button>
                                    <div className='messaging-div mb-3 px-3'>
                                        {
                                            !selectedBusinessForAppointment
                                                ?
                                                <p>No Business Selected</p>
                                                :
                                                <NewAppointment
                                                    businessIdByChatSLB={selectedBusinessForAppointment}
                                                    selectedConversationId={selectedConversationId}
                                                    handleCloseAppointmentSection={handleCloseAppointmentSection} />
                                        }
                                    </div>
                                </div>

                            )}
                        </>
                    )
                ) : null}
            </div>
        </div>
    )
}

export default ConversationAppointments;

export const Tag = styled.p.attrs(() => ({ className: 'tag is-right is-danger has-font-weight-bold', }))`
    width: 2em;
    height: 2em;
    border-radius: 100% !important;
    background: #06d755 !important; 
    top: 0;
    right: 0;
    margin-top: 25px;
  `;

export const ListLink = styled.a.attrs(() => ({
    className: 'has-background-white',
}))`
    &:hover {
      cursor: auto;
    }
  `;

export const ClickableText = styled(Text).attrs()`
  &:hover {
    cursor: pointer;
  }
`;