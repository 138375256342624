import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { isEmpty, isEqual, orderBy } from 'lodash';
import { Buttons, Button, Text, Title, Paragraph } from '@safelyq/bulma-ui-library';
import { GET_AVAILABLE_SLOTS } from './requests';
import { toast } from 'react-toastify';
import "./style.css";

const NewAppointmentSlots = ({ apptInfo, service, serviceProvider, date, currentSlot, selectedSlot, onChangeSlot, timeZone, allServiceProviders, businessIdByChatSLB }) => {
    const { businessId } = useParams();
    const business = businessId ? parseInt(businessId) : parseInt(businessIdByChatSLB);
    const [uniqueSlots, setuniqueSlots] = useState([])
    const [more, setmore] = useState(false)
    const [onLoadSlots, { loading }] = useLazyQuery(
        GET_AVAILABLE_SLOTS,
        {
            onCompleted: ({ getAvailability: slots }) => {
                let dumpArray = [];
                const hasOtherSlotsAvailable = !isEmpty(slots.others);
                if (!isEmpty(slots.first)) dumpArray.push(slots.first);
                if (!isEmpty(slots.second)) dumpArray.push(slots.second);
                if (!isEmpty(slots.third)) dumpArray.push(slots.third);
                if (!isEmpty(slots.fourth)) dumpArray.push(slots.fourth);
                if (!isEmpty(slots.fifth)) dumpArray.push(slots.fifth);
                if (hasOtherSlotsAvailable) dumpArray = dumpArray.concat(slots.others);
                const uniqueSlots = Array.from(new Set(dumpArray.map(a => (a.startTimeOnly + ':' + a.subBusiness.id))))
                    .map(refkey => {
                        return dumpArray.find(a => (a.startTimeOnly + ':' + a.subBusiness.id) === refkey)
                    });
                setuniqueSlots(uniqueSlots);
                setmore(false)
            },
            onError: (error) => {
                toast.error(error?.message)
                setuniqueSlots([])
            }

        }
    );
    const loadMore = () => {
        setmore(true)
    }

    useEffect(() => {
        if (service || serviceProvider) {

            onLoadSlots({
                fetchPolicy: 'no-cache',
                variables: {
                    service: service,
                    appointmentDate: date,
                    adults: apptInfo.adults ? parseInt(apptInfo.adults) : 0,
                    business: business,
                    appointmentWindow: 'DAY',
                    subBusinesses: !serviceProvider || parseInt(serviceProvider) === parseInt(service) ? [] : [serviceProvider],
                },
            })

        }
        else {

            setuniqueSlots([])
        }

    }, [service, serviceProvider, business, date, apptInfo.adults, onLoadSlots])

    if (loading) {
        return (<Skeleton.Input style={{ height: '200px', }} active={true} size={"large"} block={true} />)
    }
    else {
        return (
            <div>
                {service === ""
                    ?
                    <Paragraph alignment='centered' weight='bold' color='grey-light'>
                        Select a service to see available time slots
                    </Paragraph>
                    :
                    (allServiceProviders?.length > 0 && !serviceProvider)
                        ?
                        <Paragraph alignment='centered' weight='bold' color='grey-light'>  Select a service provider to see available time slots  </Paragraph>
                        :
                        (
                            <div className='mt-0'>
                                {uniqueSlots.length > 0 &&
                                    <Title size='6'
                                        color='dark'
                                        // color='info'
                                        className='mb-4'>
                                        Available Timings
                                        <small className='has-text-grey-light ml-2'>({timeZone})</small>    </Title>}
                                {uniqueSlots.length === 0 && <Paragraph alignment='centered' weight='bold' color='grey-light'>  No slots available   </Paragraph>}
                                <Buttons size='small' style={{ height: more ? "235px" : 115, overflow: "scroll" }}>
                                    {uniqueSlots.map((slot, index) => {
                                        return (
                                            <p
                                                className='is-clickable'
                                                style={{
                                                    backgroundColor: !isEqual(slot.startTimeOnly, selectedSlot?.startTimeOnly) ? "white" : "#032B98",
                                                    width: "87px",
                                                    height: "30px",
                                                    border: !isEqual(slot.startTimeOnly, selectedSlot?.startTimeOnly) ? "2px solid #d9d9d9" : "2px solid #032B98",
                                                    padding: "4px 12px",
                                                    borderRadius: "8px",
                                                    color: !isEqual(slot.startTimeOnly, selectedSlot?.startTimeOnly) ? "black" : "white",
                                                    marginRight: "10px",
                                                    marginBottom: "10px",
                                                    fontSize: "12px",
                                                    textAlign: "center"
                                                }}
                                                onClick={() => onChangeSlot(slot)}
                                            >
                                                {slot.startTimeOnly}
                                            </p>
                                        )
                                    })}
                                </Buttons>

                                {uniqueSlots.length > 9 && !more &&
                                    <Buttons size='small' style={{ justifyContent: "center" }}>
                                        <Button
                                            style={{
                                                backgroundColor: "#032B98",
                                                border: "2px solid #032B98",
                                                padding: "4px 12px",
                                                borderRadius: "8px",
                                                width: "87px",
                                                height: "30px",
                                                color: "white"
                                            }}
                                            // color='info'
                                            onClick={loadMore}   >
                                            Load More
                                        </Button>
                                    </Buttons>}
                            </div>
                        )}
            </div>

        )
    }

}

export default NewAppointmentSlots