import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useData from './hook/useData';
import { useQuery } from '@apollo/client';
import Queries from '../Common/Queries';
import './checkin.css';
import CurrentAppointmentInfo from './CurrentAppointmentInfo';
import moment from 'moment';
import ConfirmationMessage from './ConfirmationMessage';
import Constants from 'constants/index';
import Button from './Button';
import Instruction from './Instruction';
import BusinessQuestions from './BusinessQuestions';
import { useSelector } from 'react-redux';

const ConfirmCheckIn = () => {
  const { allAppointments } = useSelector((state) => state.auth);

  const { id: appointmentId } = useParams();
  const [answers, setAnswers] = useState([]);
  const [confirmedLocal, setConfirmedLocal] = useState(false);

  const [appointmentData, setAppointmentData] = useState(null);



  const [timeGap, setTimeGap] = useState(0);


  const { loading, error, refetch, data } = useQuery(Queries.GET_APPOINTMENT, {
    variables: { appointmentId: appointmentId },
  });

  const { onUpdateAppointmentStatus } = useData(
    { service: {}, subBusiness: {}, business: { details: {} } },
    refetch
  );

  const onAnswerChange = (id, name, value) => {
    const tempAnswers = [...answers];

    let answer = tempAnswers.find((answer) => answer.questionId == id);

    if (answer) {
      answer.questionId = id;
      answer[name] = value;

      setAnswers([...answers]);
    } else {
      setAnswers([...answers, { questionId: id, [name]: value }]);
    }
  };

  useEffect(() => {
    const getCurrentAppointment = allAppointments.find((appt) => appt.id === parseInt(appointmentId))
    if (getCurrentAppointment) {
      setAppointmentData(getCurrentAppointment)
    }
  }, [appointmentId, allAppointments])

  useEffect(() => {
    if (loading) {
      //message.loading('Fetching Appointment Details...');
    }

    if (error) {
      toast.error(error.message || 'Error while fetching info...');
      return null;
    }

    if (data) {
      // setAppointmentData(data.getUserAppointmentById || {});
    }
  }, [loading, error, data, appointmentId]);

  useEffect(() => {
    if (appointmentData) {
      const {
        timeZone,
        allocatedTime,
        startTime,
        business: { isAppointmentConfirmationRequired, appointmentConfirmationOffset },
      } = appointmentData;


      const nowInBizTZ = moment.tz(new Date(), timeZone);
      const apptTime = moment.tz(startTime, timeZone);
      let totalSeconds = apptTime.diff(nowInBizTZ, 'seconds'); // current time - appointment time
      let minutesTillAppointment = Math.ceil(totalSeconds / 60);

      // Get the total difference in seconds
      // if appt time is 30:00 and current time is 25:30 than in minutes it will only count till 29:30 and returns 4 min

      // Check if appointment confirmation offset is allowed and valid
      if (isAppointmentConfirmationRequired && appointmentConfirmationOffset) {
        setTimeGap(minutesTillAppointment)
      }
      // Otherwise, check if check-in time is defined
      else if (allocatedTime) {
        setTimeGap(minutesTillAppointment);
      }
      // If neither check-in nor confirmation exists, default to minutes till appointment
      else {
        setTimeGap(minutesTillAppointment);
      }

    }
  }, [appointmentData]);

  useEffect(() => {
    if (timeGap) {
      const interval = setInterval(() => {
        const {
          timeZone,
          allocatedTime,
          startTime,
          business: { isAppointmentConfirmationRequired, appointmentConfirmationOffset },
        } = appointmentData;

        var nowInBizTZ = moment.tz(new Date(), timeZone);
        var apptTime = moment.tz(startTime, timeZone);
        // Get the total difference in seconds
        // if appt time is 30:00 and current time is 25:30 than in minutes it will only count till 29:30 and returns 4 min
        let totalSeconds = apptTime.diff(nowInBizTZ, 'seconds'); // current time - appointment time
        let minutesTillAppointment = Math.ceil(totalSeconds / 60);

        // Check if appointment confirmation offset is allowed and valid
        if (isAppointmentConfirmationRequired && appointmentConfirmationOffset) {
          setTimeGap(minutesTillAppointment)
        }
        // Otherwise, check if check-in time is defined
        else if (allocatedTime) {
          setTimeGap(minutesTillAppointment);
        }
        // If neither check-in nor confirmation exists, default to minutes till appointment
        else {
          setTimeGap(minutesTillAppointment);
        }

      }, 30000);
      // }, 60000);
      return () => clearInterval(interval);
    }
  }, [timeGap, appointmentData]);

  const handleStatusChange = (status, userResponses) => {
    onUpdateAppointmentStatus({
      variables: {
        appointmentId: appointmentId,
        newStatus: status,
        userResponses: userResponses ?? [],
      },
    });
  };

  const handleConfirmed = () => {
    handleConfirmedLocal();

    handleStatusChange(Constants.CONFIRMED_STATUS_NAME, answers);
  };

  const handleConfirmedLocal = () => {
    setConfirmedLocal((prev) => !prev);
  };

  return (
    <div className='mb-6'>
      {appointmentData && (
        <Fragment>
          <section className='px-1 pt-0 pb-1'>
            <CurrentAppointmentInfo appointmentData={appointmentData} />
          </section>

          <section className='section pt-4 px-5 pb-2'>
            <div className='container px-1 has-text-centered '>
              {confirmedLocal &&
                appointmentData?.business?.questions?.length > 0 ? (
                <QuestionPanel
                  data={appointmentData}
                  onConfirm={handleConfirmed}
                  answers={answers}
                  onAnswerChange={onAnswerChange}
                />
              ) : (
                <div className='is-flex is-flex-direction-column is-align-items-center'>
                  <ConfirmationMessage
                    data={appointmentData}
                    interval={timeGap}
                    confirmedLocal={confirmedLocal}
                    onConfirm={handleConfirmed}
                    onLocalConfirm={handleConfirmedLocal}
                    onCheckIn={() =>
                      handleStatusChange(Constants.CHECKED_IN_INPUT_NAME)
                    }
                  />
                </div>
              )}
            </div>
          </section>
        </Fragment>
      )}
    </div>
  );
};

const QuestionPanel = ({ ...props }) => {
  const { onAnswerChange, data, onConfirm, answers } = props;

  // const { preCheckinInstructions, questions } = useData(data, () => { });
  const { specialInstructions, questions } = useData(data, () => { });


  return (
    <Fragment>
      <section className='px-1 pt-2 pb-1'>
        <Instruction preCheckinInstructions={specialInstructions} />
      </section>

      <section className='section container px-0 py-4'>
        <BusinessQuestions
          questions={questions}
          onAnswerChange={onAnswerChange}
        />

        <Button
          clicked={onConfirm}
          disable={questions.length > 0 && answers.length < questions.length}
          label='Confirm'
        />
      </section>
    </Fragment>
  );
};

export default ConfirmCheckIn;
