import React, { useState } from 'react';
import { Text, Button } from '@safelyq/bulma-ui-library';
import StarRatings from 'react-star-ratings';
//for firestore
import { useMutation } from '@apollo/client';
import { SAVE_BUSINESS_RATING } from './requests';
import { toast } from 'react-toastify';
import { Spin } from 'antd';
import { useEffect } from 'react';

export const AddNewRating = ({ refetch, businessId, isLoggedIn, tryToLogin }) => {

  const [isOpen, setOpen] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);

  const [commentText, setCommentText] = useState('');
  const [saveLoading, setSaveLoading] = useState(false)

  const [onSaveBusinessRating] = useMutation(SAVE_BUSINESS_RATING);

  const isDisabled = !ratingValue || !commentText;

  const handleCommentTextChange = ({ target: { value } }) => setCommentText(value);
  const handleRatingValueChange = (value) => setRatingValue(value);

  const handleSubmitRating = async () => {
    if (!isLoggedIn) {
      const data = { businessId, ratingValue, commentText }
      localStorage.setItem('ratingData', JSON.stringify(data));
      await tryToLogin();
      return;
    }
    localStorage.removeItem('ratingData');
    try {
      setSaveLoading(true)
      const response = await onSaveBusinessRating({ variables: { ratingInput: { businessId, ratingType: 'OVERALL_SERVICE', ratingValue, commentText, }, }, })
      if (response.data.saveBusinessRatings.isSaved === false) {
        const errorMessage = { code: 403, message: response.data.saveBusinessRatings.errorMessage };
        throw errorMessage;
      }
      else {
        await refetch()
        setOpen(false)
        // setRatingValue(0)
        setCommentText('')
        toast.success(" Review Submitted Successfully")
      }
      setSaveLoading(false)
    } catch (error) {
      console.log(error.message);
      toast.error(error.message)
      setSaveLoading(false)
    }

  }

  useEffect(() => {
    (async () => {
      const ratingData = JSON.parse(localStorage.getItem('ratingData'));
      if (ratingData) {
        try {
          const { businessId, ratingValue, commentText } = ratingData;
          setSaveLoading(true)
          const response = await onSaveBusinessRating({ variables: { ratingInput: { businessId, ratingType: 'OVERALL_SERVICE', ratingValue, commentText, }, }, })
          if (response.data.saveBusinessRatings.isSaved === false) {
            const errorMessage = { code: 403, message: response.data.saveBusinessRatings.errorMessage };
            throw errorMessage;
          }
          else {
            await refetch()
            setOpen(false)
            // setRatingValue(0)
            setCommentText('')
            toast.success(" Review Submitted Successfully")
          }
          setSaveLoading(false)
          localStorage.removeItem('ratingData');
        } catch (error) {
          setSaveLoading(false)
          console.log("Errrrror", error);
          toast.error(error.message);
        }
      }
    })();
  }, [])

  return (
    <div className='mt-3 mb-4'>
      <div className='is-flex is-justify-content-space-between'>
        <small className='is-size-6'>Tell others what you think</small>
        <StarRatings rating={ratingValue} starRatedColor='rgb(26 17 186)' starHoverColor='rgb(26 17 186)' starSpacing='7px' starDimension='25px' changeRating={handleRatingValueChange} />
      </div>

      {!isOpen && (<Text className='has-text-link is-clickable' onClick={() => setOpen(true)}>  <i>Write a review</i>  </Text>)}
      {isOpen && (
        <div>
          <textarea className='textarea' rows={2} placeholder='Describe your experience' onChange={handleCommentTextChange} />
          <Spin spinning={saveLoading}>
            <Button size='small' color='info' className='is-right mt-2' disabled={isDisabled} onClick={handleSubmitRating}   >
              Submit
            </Button>
          </Spin>

        </div>
      )}
    </div>
  );
};
