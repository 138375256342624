import React, { useState, useEffect, useRef } from 'react';
import { useData } from '../hooks';
import PaymentUI from './PaymentUI';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setStripeTokenGlobally } from 'Redux/MiscSlice/Misc';

export default function StripeComponent({ accessToken }) {
    const [dataIdUsed, setDataIdUsed] = useState(null);

    const name = "Business Payment";
    const boxRef = useRef();
    const location = useLocation();
    const history = useHistory();
    // Create an instance of URLSearchParams with the search part of the URL
    const queryParams = new URLSearchParams(location.search);

    const userinfo = useSelector(state => state?.auth?.userinfo);
    const stripeAfterPaymentToken = useSelector(state => state?.misc?.stripeAfterPaymentToken);
    const dispatch = useDispatch();

    // Later when retrieving
    const isLoggingInForPayment = localStorage.getItem("userLoggingInForPayment") === "true";

    // If user is logged in for the payment than call the payment function
    useEffect(() => {
        if (isLoggingInForPayment) {
            const email = userinfo?.email

            email && setAutoPaymentLoading(true);
            email && handleSubmitPaymentFunction(email)
        }
    }, [isLoggingInForPayment, userinfo?.email])

    // Get specific parameters
    const dataId = queryParams.get('dataId');
    const amount = queryParams.get('amount');
    const businessId = queryParams.get('businessId');

    const [stripeVariables, setStripeVariables] = useState({
        amount: amount * 100 || 0, // converting cents to dollars 
        businessId: businessId || 439, // 439 is testing business id
        dataId: dataId || 0,
    })
    const [emailStatuses, setEmailStatuses] = useState({
        emailExists: null,
        validEmail: null
    });


    const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
    const [autoPaymentLoading, setAutoPaymentLoading] = useState(null);
    const [paymentReciept, setPaymentReciept] = useState(null);


    useEffect(() => {
        if (isPaymentCompleted) {
            // reset the global state
            localStorage.removeItem("userLoggingInForPayment");
            setAutoPaymentLoading(false);
            dispatch(setStripeTokenGlobally(0))
        }
    }, [isPaymentCompleted])


    // get publishable key and collect business payment
    const {
        publishableKey,
        loadingBusinessPayment,
        onCollectBusinessPayment,
        onGetStripePublishableKey,
        onGetBusinessPaymentData,
        businessData
    } = useData(setIsPaymentCompleted, setEmailStatuses, setAutoPaymentLoading, setPaymentReciept);

    useEffect(() => {
        // check before the payment that if the data is not already used
        if (businessData) {
            const isSuccess = businessData?.getBusinessPaymentData?.isSuccess;
            const errorMessage = businessData?.getBusinessPaymentData?.errorMessage;

            if (!isSuccess && errorMessage) {
                setDataIdUsed(true);
            }
            else if (isSuccess) {
                setStripeVariables({
                    ...stripeVariables,
                    amount: parseFloat(businessData?.getBusinessPaymentData?.amount) * 100// set amount
                })
                setDataIdUsed(false) // allow the payment
            }
        }
    }, [businessData])


    // Get publishable key
    useEffect(() => {
        onGetStripePublishableKey()
    }, [])

    // get business payment data
    useEffect(() => {
        if (stripeVariables?.dataId) {
            onGetBusinessPaymentData({ variables: { dataId: dataId } })
        }
    }, [stripeVariables?.dataId])

    useEffect(() => {
        function handleClickOutside(event) {
            if (boxRef.current && !boxRef.current.contains(event.target)) {
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [boxRef]);

    const handleToken = (token) => {
        token && dispatch(setStripeTokenGlobally(token?.id)); // set the token globally

        onCollectBusinessPayment({
            variables: {
                collectBusinessPaymentInput: {
                    email: token?.email || "", // Use email from the token
                    tokenId: token.id, // Use token id from Stripe
                    dataId: stripeVariables?.dataId || "", // Ensure dataId is set
                },
            },
        });
    }

    const handleSubmitPaymentFunction = (email) => {

        onCollectBusinessPayment({
            variables: {
                collectBusinessPaymentInput: {

                    email: email || "", // Use email from the token
                    tokenId: stripeAfterPaymentToken, // Use token id from Stripe
                    dataId: stripeVariables?.dataId || "", // Ensure dataId is set

                    isValidEmail: userinfo?.email ? true : false, // if email exists means user has logged in and send flag that email is valid
                },
            },
        });
    }

    const paymentUIProps = {
        publishableKey,
        boxRef,
        handleToken,
        name,
        stripeVariables,
        isPaymentCompleted,
        loadingBusinessPayment,
        dataId,
        dataIdUsed,
        emailStatuses,
        handleSubmitPaymentFunction,
        autoPaymentLoading,
        paymentReciept
    }

    return (
        <>
            <PaymentUI paymentUIProps={paymentUIProps} />
        </>
    );
}
