import React, { useEffect } from 'react';
import { prefixHolder } from 'services/prefix-holder';

// Api to fetch token
const FetchAccessToken = ({ setAccessToken }) => {

    const handleFetchToken = async () => {
        const urlEncodedData = new URLSearchParams();
        urlEncodedData.append('grant_type', 'client_credentials');
        urlEncodedData.append('client_id', 'safelyq.api');
        urlEncodedData.append('client_secret', '893bfc0b-880c-4f5e-b258-41d007e08860');

        try {
            const response = await fetch(`${prefixHolder?.Gateway}/connect/token`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: urlEncodedData.toString(),
            });
            const data = await response.json();

            const token = data.access_token;  // Adjust if the key is different


            if (token) {
                setAccessToken(token);
            }
        } catch (error) {
            console.error('Error fetching access token:', error);
        }
    };


    // Call the api
    useEffect(() => {
        handleFetchToken();
    }, [])

    return (<></>);
};

export default FetchAccessToken;
