import React, { useState } from 'react'
import { Button, TextInput } from '@safelyq/bulma-ui-library';
import { useProfile } from 'hooks/useProfile';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

const ExistingEmailFlow = ({ existingEmailFlowProps }) => {
    const {
        handleSubmitPaymentFunction,
        autoPaymentLoading,
        loadingBusinessPayment
    } = existingEmailFlowProps;

    const [newEmailAddress, setNewEmailAddress] = useState(null);

    const { login } = useProfile();

    const attemptLogin = () => {
        localStorage.setItem("userLoggingInForPayment", "true")
        login();
    }

    const isEmailValidAndExists = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        // Check if the email is in a valid format
        if (!emailRegex.test(newEmailAddress)) {
            toast.error("Please enter a valid email")
            return false;
        }
        else {
            return true;
        }
    }

    return (
        <div>
            <p className='is-size-6 has-text-grey-dark my-2'>The email address you provided is already in use. Please enter a different email address or log in with SafelyQ.</p>
            <p>Email</p>

            <div className='is-flex my-2'>
                <TextInput
                    className=''
                    value={newEmailAddress}
                    placeholder="Please enter a new email address"
                    onChange={(e) => setNewEmailAddress(e.target.value)}
                />

                <Button
                    color='info'
                    className=' ml-2 has-text-centered is-half'
                    onClick={() => newEmailAddress && isEmailValidAndExists() && handleSubmitPaymentFunction(newEmailAddress)}
                >
                    Submit {loadingBusinessPayment && newEmailAddress && <ClipLoader style={{ marginLeft: "10px" }} size='24' color='white' />}
                </Button>
            </div>

            <hr />
            <p className='has-text-centered is-size-6 has-text-grey-dark my-2'>
                Or Login with SafelyQ
            </p>
            <Button
                onClick={() => attemptLogin()}
                color='info'
                className='has-text-centered mt-1 is-fullwidth'
            >
                Login
                {autoPaymentLoading && <ClipLoader className="ml-3" size='24' color='white' />}
            </Button>

        </div>
    )
}

export default ExistingEmailFlow