import React, { useEffect, useRef, useState } from 'react'
import { MessageBoxScrollList, StyledMessageLoader } from '../../ManageUserMessages.styled'
import MessageItem from '../MessageItem/MessageItem'
const MessageBody = ({ allchats, loading, onGetBusinessConversation, messagesPagination, setMessagesPagination, handleScroll, messageListRef }) => {

    useEffect(() => {
        const currentRef = messageListRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    return (
        <>
            <MessageBoxScrollList id='scroll-list' style={{ scrollbarWidth: "none", height: "100%" }} ref={messageListRef}>
                {allchats?.map((chat) => <MessageItem chat={chat} />)}

            </MessageBoxScrollList>
            {/* <div style={{ background: "red", height: "200px" }}></div> */}
        </>

    )
}

export default MessageBody