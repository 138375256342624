import React from 'react'
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { GiEmptyHourglass } from "react-icons/gi";
import styled from 'styled-components';
import moment from 'moment';
import MessageBox from 'features/message-feature/src/routes/ManageUserMessages/MessageBox/MessageBox';
import NewMessageBoxPopup from 'features/message-feature/src/routes/ManageUserMessages/MessageBox/NewMessageBoxPopup';

const ConversationMessagingDesktop = ({ conversationMessagingDesktopProps }) => {

    const {
        showStatus,
        setShowStatus,
        selectedConversationId,
        showMessagingScreens,
        selectedBusinessForMessage,
        conversationMessagesList,
        messagingDivRef,
        handleMessagingAllowed,
        chatRights,
        setChatRights,
        newMessagePopup,
        handleCloseFunction,
    } = conversationMessagingDesktopProps;

    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';

    return (
        <div>
            <div className='is-flex is-justify-content-space-between px-3 is-clickable mb-3 py-1 custom-tab-dropdown'
                onClick={() => setShowStatus({ ...showStatus, messaging: !showStatus?.messaging })}
            >
                <p className={`is-size-5 has-text-weight-regular  ${showStatus?.messaging ? "has-text-black" : "has-text-grey"}`}>
                    Messaging
                </p>
                {showStatus?.messaging
                    ?
                    <FaChevronUp className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.messaging ? "has-text-black" : "has-text-grey"}`} />
                    :
                    <FaChevronDown className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.messaging ? "has-text-black" : "has-text-grey"}`} />}
            </div>

            <div ref={messagingDivRef}>
                {showStatus?.messaging ? (
                    !selectedConversationId ? (
                        <div className='is-flex is-justify-content-center mb-2' style={{ width: "100%" }}>
                            <GiEmptyHourglass color='gray' fontSize={26} />
                            <p className='ml-2 has-text-weight-semibold has-text-grey-dark'>No Conversation Selected</p>
                        </div>
                    ) : (
                        <>
                            <div className='scroller-for-modules'>
                                {/* Check if the "list" messaging screen should be shown */}
                                {showMessagingScreens?.list && (
                                    <div className='mb-3 mx-3'>
                                        {
                                            conversationMessagesList?.length === 0
                                                ?
                                                <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                                    <GiEmptyHourglass color='gray' fontSize={26} />
                                                    <p className='ml-2 has-text-weight-semibold has-text-grey-dark'>No Conversations Exist</p>
                                                </div>
                                                :
                                                conversationMessagesList?.map((chat) => {
                                                    return (
                                                        <ListItem
                                                            className='mb-4'
                                                            style={{ borderRadius: "12px" }}
                                                            onClick={() => handleMessagingAllowed(
                                                                chat?.business?.id,
                                                                chat?.business?.businessSubscription?.subscriptionPlan,
                                                                chat?.business?.messaging)}>
                                                            <div className='col-2'>
                                                                <figure className='media-left'>
                                                                    {chat?.business?.picture && chat?.business?.picture?.path ? (
                                                                        <p className='image is-48x48'>
                                                                            <img className='image is-48x48 is-rounded'
                                                                                style={{ objectFit: "contain" }}
                                                                                src={chat?.business?.picture?.path}
                                                                                alt="pic"
                                                                                onError={(e) => e.target.src = emptyThumbnail}
                                                                            />   </p>
                                                                    ) : (
                                                                        <p className='image is-48x48 has-background-grey-light rounded is-flex is-justify-content-center is-align-items-center'>
                                                                            {String(chat?.business.name).charAt(0)}
                                                                        </p>
                                                                    )}
                                                                </figure>
                                                            </div>
                                                            <div className='col-10 pl-2'>
                                                                <div className='row g-0'>
                                                                    <div className='col-7 is-flex is-flex-direction-column is-justify-content-space-evenly'>
                                                                        <p className='mb-0 is-size-6'>
                                                                            <strong>
                                                                                {chat?.business?.name?.length > 10 ? String(chat?.business?.name).substring(0, 10) + '...' : chat?.business?.name}
                                                                            </strong>
                                                                        </p>
                                                                        <EllipsisText className='is-size-6'>
                                                                            {chat?.recentMessages[0]?.message?.commentText}
                                                                        </EllipsisText>
                                                                    </div>
                                                                    <div className='col-5 has-text-right'>
                                                                        <span className='  is-size-7 has-text-grey-light'>
                                                                            {moment.utc(chat?.business?.recentMessageTime).local().calendar()}{' '}
                                                                        </span>
                                                                        {chat.unreadCount > 0 && (
                                                                            <Tag>{chat?.unreadCount > 9 ? '9+' : chat?.unreadCount}</Tag>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ListItem>
                                                    )
                                                })}
                                    </div>
                                )}
                            </div>

                            {/* Check if the chat screen should be shown */}
                            {showMessagingScreens?.chat && (
                                <div className='messaging-div mb-3 px-3'>
                                    <MessageBox
                                        businessIdFromSLB={selectedBusinessForMessage}
                                        selectedConversationId={selectedConversationId}
                                        chatRights={chatRights}
                                        setChatRights={setChatRights} />
                                </div>
                            )}

                            {/* Check if the modal for a new message should be shown */}
                            {showMessagingScreens?.modal &&
                                <NewMessageBoxPopup
                                    isOpen={newMessagePopup.status}
                                    onClose={handleCloseFunction}
                                    businessId={newMessagePopup.businessId}
                                    selectedConversationId={selectedConversationId}
                                />
                            }
                        </>
                    )
                ) : null}
            </div>

            {/* Divider */}
            <hr className='mt-3' style={{ width: '35%', margin: '10px auto', height: '2px', backgroundColor: '#afafaf', border: 'none' }} />
        </div>
    )
}

export default ConversationMessagingDesktop;

export const ListItem = styled.li.attrs(() => ({ className: 'p-3 row g-0 align-items-center', }))`
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    background: ${({ bgGrey, active }) => bgGrey || active ? 'rgba(0,0,0,0.04)' : 'white'};
    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.04);
    }
  `;

export const EllipsisText = styled.p.attrs(() => ({}))` 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Tag = styled.p.attrs(() => ({ className: 'tag is-right is-danger has-font-weight-bold', }))`
    width: 2em;
    height: 2em;
    border-radius: 100% !important;
    background: #06d755 !important; 
    top: 0;
    right: 0;
    margin-top: 25px;
  `;

export const ListLink = styled.a.attrs(() => ({
    className: 'has-background-white',
}))`
    &:hover {
      cursor: auto;
    }
  `;